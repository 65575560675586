import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";

interface iSqlTableMagicMobile {
    data: any[];
    customMobileRow?: any;
    sortModel: any,
    perPage:number;
    setPerPage:(p:number) => void;
    page:number;
    setPage:(p:number) => void;
    onSortModelChange: (newSortModel: any) => void,
}

const SqlTableMagicMobile = ({ data, customMobileRow, page, perPage, setPage, setPerPage}: iSqlTableMagicMobile) => {
    // const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0)
    const CustomRow = customMobileRow
    const sStart = (page - 1) * perPage;
    const sEnd = ((page) * perPage);

    useEffect(() => {
        const pages = Math.ceil(data.length / perPage);
        setPages(pages)
        console.log(pages, page)
        if (page > pages) {
            setPage(1)
        }
    }, [data])
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };
    return <>{data.slice(sStart, sEnd).map((row: any, dIndex: number) => {
        return <CustomRow key={dIndex} row={row} />
    })}
        <Pagination count={pages} page={page} size='large' onChange={handleChange} hideNextButton hidePrevButton />
    </>
}


export default SqlTableMagicMobile;