import { CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Approve from "../../util/approve";
import doRequest from "../../util/axios";
import DataDump from "../../util/dataDump";
import Decline from "../../util/decline";
import DisplaySomething from "../../util/displaySomething";
import GreyBox, { alwaysFullSpacing, containerSpacing } from "../../util/greyBox";
import NotFound from "../../util/not-found";
import SqlTableMagic from "../../util/sqlTableMagic";
import TabsThing from "../../util/tabs";
import DebugButton from "../../util/debugButton";
import Requote from "../../util/requote";
interface iState{
    tabIndex:number;
    scrollPosition:number;
}
const Quote = () => {
    const params = useParams();
    const [quote, setQuote] = useState<any>();
    const [title, setTitle] = useState<string>();
    const [tabIndex, setTabIndex] = useState(0);
    const [tabsInfo, setTabsInfo] = useState<any[]>();
    const mounted = useRef(false);


    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const uniqueId = "QuotePg"
    useEffect(() => {
        const loaded = searchParams.get("loaded"+uniqueId)
        if (loaded == null) {
            setSearchParams((prev) => {
                prev.set("loaded"+uniqueId, Date.now().toString())
                // prev.set(__SCROLL_LEFT, debouncedSearchTerm.left.toString())
                return prev
            }, { replace: true })
        } else {
            const stateT = window.localStorage.getItem(uniqueId+loaded);
            if (stateT !== null) {
                const state = JSON.parse(stateT) as iState | undefined;
                console.log("STATE", stateT, state)
                if (state && state) {
                    setTabIndex(state.tabIndex);
                   
                    window.scrollTo(0, state.scrollPosition);
                }
            }
        }
    }, []);

    useEffect(() => {

        const thisPageState: iState = {
            scrollPosition: window.scrollY,
            tabIndex
        };
        const loaded = searchParams.get("loaded"+uniqueId)

        window.localStorage.setItem( uniqueId + loaded, JSON.stringify(thisPageState));

    }, [tabIndex]);

    const navigateTo = (navigateTo: string) => {
        const thisPageState: iState = {
            scrollPosition: window.scrollY,
            tabIndex
        };
        const loaded = searchParams.get("loaded"+uniqueId)
        window.localStorage.setItem( uniqueId + loaded, JSON.stringify(thisPageState));
        navigate(navigateTo, {});
    };





    const Go = async () => {
        const response = await doRequest<any>(`quotes/quote/${params.id}`, 'GET', {})
        console.log("static", response, response[0], response[0].length)
        if (response[0] && response[0].length > 0) {
            setQuote(response[1][0])
            setTabsInfo(response[0])
            setTitle("Quote #" + response[1][0].JobQuoteNumber + " - " + response[1][0].CompanyLocation1Address + "  (Call Ref: " + response[1][0].JobCallRef + ")")
        } else {
            setQuote(null)
        }
    }
    useEffect(() => {
        if (mounted.current === false) {
            mounted.current = true;
            Go();
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id])

    const reload = () => {
        Go();
    }

    if (quote === undefined) {
        return <CircularProgress />
    }
    if (quote === null) {
        return <NotFound />
    }

    const headSX = {
        paddingLeft: 1
    }


    return <Grid container spacing={0}>
        <Grid item  {...alwaysFullSpacing}>
            <Typography sx={headSX} variant="h3">{title}</Typography>

            <Grid item container  {...alwaysFullSpacing} spacing={0}>
                <Grid item {...containerSpacing}>
                    <GreyBox>
                        <Typography sx={headSX} variant="h4">Site Details</Typography>
                        <DisplaySomething navigateTo={navigateTo} payload={quote} field="CompanyLocation1Address" text="Site Name" type="siteName" size={12} />
                        <DisplaySomething navigateTo={navigateTo} payload={quote} field="CompanyLocationAddress" text="Address" size={12} />
                        <DisplaySomething navigateTo={navigateTo} payload={quote} field="CompanyLocationPostcode" text=" Postcode" size={12} />
                    </GreyBox>
                    <GreyBox>
                        <Typography sx={headSX} variant="h4">Account Manager Details</Typography>
                        <DisplaySomething navigateTo={navigateTo} payload={quote} field="AccountManager" text="Account Manager" size={12} />
                        <DisplaySomething navigateTo={navigateTo} payload={quote} field="AccountManagerEmail" text="Account Manger Email" type="email" size={12} />
                        <DisplaySomething navigateTo={navigateTo} payload={quote} field="AccountManagerPhone" text="Account Manager Phone" type="phone" size={12} />

                    </GreyBox>
                </Grid>
                <Grid item {...containerSpacing}>
                    <GreyBox>
                        <Typography sx={headSX} variant="h4">Quote Details</Typography>
                        <DebugButton data={quote} />
                        {(quote.JobQuoteStatusID === 1 || quote.JobQuoteStatusID === -2) &&
                            <Grid container spacing={0} sx={{ paddingTop: 2, paddingLeft: 4, paddingRight: 4, textAlign: "center" }}>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={6} sx={{ textAlign: 'left' }}>
                                    <Approve object={quote} jobId={quote.id} onClose={reload} width="20vw" />
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={6} sx={{ textAlign: 'right' }}>
                                    <Decline jobId={quote.id} onClose={reload} width="20vw" />
                                </Grid>
                            </Grid>}
                        {quote.JobRequoteYN === true &&
                            <Grid container spacing={0} sx={{ paddingTop: 2, paddingLeft: 4, paddingRight: 4, textAlign: "center" }}>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Requote object={quote} jobId={quote.id} onClose={reload} width="20vw" />
                                </Grid>

                            </Grid>}
                        <DisplaySomething navigateTo={navigateTo} payload={quote} field="JobQuoteStatus" text="Status" size={12} />
                        <DisplaySomething navigateTo={navigateTo} payload={quote} field="JobQuoteNote" text="Note" size={12} />

                        <DisplaySomething navigateTo={navigateTo} payload={quote} field="JobQuoteStatus" text="Status" size={12} />
                        <DisplaySomething navigateTo={navigateTo} payload={quote} field="JobQuoteNote" text="Note" size={12} />
                        <DisplaySomething navigateTo={navigateTo} payload={quote} field="JobQuoteCost" type="money" text="Cost" size={12} />
                        <DisplaySomething navigateTo={navigateTo} payload={quote} field="JobQuoteExpiresDate" text="Expires" type="date" size={12} />


                        <DisplaySomething navigateTo={navigateTo} payload={quote} field="JobQuotePONumber" text="Your PO Number" size={12} />

                        {quote.QuoteAcceptDeclineDate != null && <DisplaySomething navigateTo={navigateTo} payload={quote} field="QuoteAcceptDeclineDate" text={`Accept / Decline Date`} type="date" size={12} />}
                        {quote.QuoteAcceptDeclineBy != null && <DisplaySomething navigateTo={navigateTo} payload={quote} field="QuoteAcceptDeclineBy" text={`Accepted / Declined By`} size={12} />}

                        {quote.JobID !== 0 && <DisplaySomething navigateTo={navigateTo} payload={quote} field="JobID" text="Job" type="job" size={12} />}
                        <DisplaySomething navigateTo={navigateTo} payload={quote} field="JobCallRef" text="Call Ref" size={12} />
                    
                        {quote.JobRelatedID !== 0 && <DisplaySomething navigateTo={navigateTo} payload={quote} field="JobRelatedID" text="Related Job" type="job" size={12} />}
                        {quote.JobRelatedPreviousID !== 0 && <DisplaySomething navigateTo={navigateTo} payload={quote} field="JobRelatedPreviousID" text="Previous Job" type="job" size={12} />}
                    </GreyBox>

                    {/* <GreyBox>
                    <Typography sx={headSX} variant="h4">Add Note</Typography>
                    <AddNote noteTypeId={1} refId={quote.id} />
                       </GreyBox> */}

                </Grid>
                <DebugButton data={quote} />

            </Grid >
            {tabsInfo !== undefined && <Grid item {...alwaysFullSpacing}>
                <GreyBox>
                    <TabsThing
                        tabIndex={tabIndex}
                        setTabIndex={setTabIndex}
                        tabs={tabsInfo.map((ti, tii) => {
                            return { title: ti.FN, content: <SqlTableMagic navigateTo={navigateTo}  combinedSearch={false} title={''} url={`jobs/${ti.LF}/${params.id}`} requestType='GET' tblIndex={tii} uniqueId={`quote_${tii}`} /> }
                        })} />
                </GreyBox>
            </Grid>}
        </Grid>
        <DataDump data={quote} />
    </Grid >
}


export default Quote