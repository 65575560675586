import { Box, CircularProgress, Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import doRequest from "../../util/axios";
import NotFound from "../../util/not-found";
import { useNotify } from "../../util/notify";

import FormContainer from "../../util/form-container";
import NewJobForm from "./jobForm";

interface iDateFromString {
    string: string;
}
interface iState {
    tabIndex: number;
    scrollPosition: number;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: "90%",
    width: "75%",
    bgcolor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    overflow: "scroll",
    p: 4,
};
const NewJobPage = () => {
    const [error, setError] = useState<string>("");
    const [loaded, setLoaded] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);


    const [locations, setLocations] = useState<any[]>([]);
    const [assets, setAssets] = useState<any[]>([]);
    const { displayNote } = useNotify()
    const mounted = useRef(false);
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const uniqueId = "NewJobPg"
    useEffect(() => {
        const loaded = searchParams.get("loaded" + uniqueId)
        if (loaded == null) {
            setSearchParams((prev) => {
                prev.set("loaded" + uniqueId, Date.now().toString())
                // prev.set(__SCROLL_LEFT, debouncedSearchTerm.left.toString())
                return prev
            }, { replace: true })
        } else {
            const stateT = window.localStorage.getItem(uniqueId + loaded);
            if (stateT !== null) {
                const state = JSON.parse(stateT) as iState | undefined;
                console.log("STATE", stateT, state)
                if (state && state) {
                    setTabIndex(state.tabIndex);

                    window.scrollTo(0, state.scrollPosition);
                }
            }
        }
    }, []);

    useEffect(() => {

        const thisPageState: iState = {
            scrollPosition: window.scrollY,
            tabIndex
        };
        const loaded = searchParams.get("loaded" + uniqueId)

        window.localStorage.setItem(uniqueId + loaded, JSON.stringify(thisPageState));

    }, [tabIndex]);

    const navigateTo = (navigateTo: string) => {
        const thisPageState: iState = {
            scrollPosition: window.scrollY,
            tabIndex
        };
        const loaded = searchParams.get("loaded" + uniqueId)
        window.localStorage.setItem(uniqueId + loaded, JSON.stringify(thisPageState));
        navigate(navigateTo, {});
    };





    const Go = async () => {
        const locations = await doRequest<any>(`locations/list`, 'GET', {})
        const assets = await doRequest<any>(`assets/list`, 'GET', {})
        console.log("static", locations, assets);

        if (locations.data && assets.data) {
            console.log("static", locations, assets);
            setAssets(assets.data);
            setLocations(locations.data)
            setLoaded(true)
            console.log("static", locations, assets);
        }

    }


    useEffect(() => {
        if (mounted.current === false) {
            mounted.current = true;
            Go();
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    if (loaded === false) {
        return <CircularProgress />
    }

    return <Box sx={{ m: 2 }}><FormContainer
        buttonText="Create Job"
        data={{ location: '', assets: [] }}
        onSubmit={async (data: any) => {
            if (data.assets && data.assets.length > 0) {
                setError('');
                try {
                    const response = await doRequest<any>(`jobs/new`, 'POST', { ...data })
                    console.log(response)
                    if (response.x === 'OK') {
                        displayNote && displayNote('Job created, you will be contacted soon.', 'success')
                    } else {
                        displayNote && displayNote('Something went wrong, Please try again or contact support.', 'error')
                    }
                } catch (ex) {
                    console.log(ex);
                    displayNote && displayNote('Something went wrong, Please try again or contact support.', 'error')
                }
            } else {
                setError("Please add some appliances")
            }

        }}><Grid container spacing={1}>
            <NewJobForm locations={locations} assets={assets} error={error} setError={setError} />
        </Grid>
    </FormContainer></Box>

}


export default NewJobPage