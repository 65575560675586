import { CircularProgress, Grid, TableHead } from "@mui/material";
import GreyBox, { alwaysFullSpacing } from "../../util/greyBox";
import { useEffect, useMemo, useRef, useState } from "react";
import doRequest from "../../util/axios";
import DebugButton from "../../util/debugButton";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Order, ScheduleHeader, getComparator, stableSort } from "./utils";
import { useNotify } from "../../util/notify";

function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}
interface iState {
    order:string, orderBy:string,
    scrollPosition: number;
}




const SiteSpend = () => {
    let query = useQuery();

    const mounted = useRef(false)
    const [data, setData] = useState<any[]>([]);
    const [name, setName] = useState("");
    const { displayNote } = useNotify();
    const [orderBy, setOrderBy] = useState<string>("Site");
    const [order, setOrder] = useState<Order>("asc");
    let [searchParams, setSearchParams] = useSearchParams();
    const uniqueId = "SiteSpendPg"
    useEffect(() => {

        const loaded = searchParams.get("loaded" + uniqueId)
        if (loaded == null) {
            setSearchParams((prev) => {

                prev.set("loaded" + uniqueId, Date.now().toString())
                // prev.set(__SCROLL_LEFT, debouncedSearchTerm.left.toString())
                return prev
            }, { replace: true })

        } else {
            const stateT = window.localStorage.getItem(uniqueId + loaded);
            if (stateT !== null) {
                const state = JSON.parse(stateT) as iState | undefined;
                console.log("STATE", stateT, state)
                if (state && state) {

                    window.scrollTo(0, state.scrollPosition);
                }
            }

        }
    }, []);


    useEffect(() => {
        const thisPageState: iState = {
            scrollPosition: window.scrollY,
            orderBy, order
        };
        const loaded = searchParams.get("loaded" + uniqueId)

        window.localStorage.setItem(uniqueId + loaded, JSON.stringify(thisPageState));

    }, [orderBy, order]);
 

    const beforeNavigate = () => {
        const thisPageState: iState = {
            scrollPosition: window.scrollY,
            orderBy, order
        };
        const loaded = searchParams.get("loaded" + uniqueId)
        window.localStorage.setItem(uniqueId + loaded, JSON.stringify(thisPageState));
    };









    const { id } = useParams();
    const navigate = useNavigate();


    useEffect(() => {
        const start = query.get("start");
        const end = query.get("end");
        const Go = async () => {
            const response = await doRequest<any>(`reports/sReportSpend/${id}?start=${start}&end=${end}`, 'GET', {})
            console.log(response)
            setData(response);

            if (response.length >= 1) {
                setName(response[0].Site)
            }
        }
        if (mounted.current === false) {
            mounted.current = true;

            Go();
        }
    }, [id]);


    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: string,
    ) => {
        console.log(event)
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const visibleRows = useMemo(
        () =>
            stableSort(data, getComparator(order, orderBy)),
        [order, orderBy, data],
    );

    return <Grid container spacing={0}>
        <Grid item {...alwaysFullSpacing}>
            <GreyBox>
                <Grid container>

                    <Grid item md={12}>
                        <h2>Site Spend {name}</h2>



                        {visibleRows.length > 0 ? <TableContainer sx={{ maxHeight: "70vh" }}>
                            <Table sx={{ minWidth: 500 }} aria-label="Service S" stickyHeader>
                                <TableHead>

                                    <TableRow>
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="AssetNumber" title="Asset Number" onRequestSort={handleRequestSort} />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="Type" title="Appliance" onRequestSort={handleRequestSort} align="center" />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="Make" title="Make" onRequestSort={handleRequestSort} align="center" />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="Model" title="Model" onRequestSort={handleRequestSort} align="center" />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="SerialNumber" title="Serial Number" onRequestSort={handleRequestSort} align="center" />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="Type" title="Type" onRequestSort={handleRequestSort} align="center" />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="Condition" title="Condition" onRequestSort={handleRequestSort} align="center" />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="RepairSpend" title="Repair Spend" onRequestSort={handleRequestSort} align="center" />

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(visibleRows).map((row) => {
                                        if (typeof row.Appliance_Background === "string") {
                                            return <TableRow key={row.name} className={row.Appliance_Background} onClick={(ev) => {
                                                ev.preventDefault();
                                                if (row.ApplianceID !== 0) {
                                                    beforeNavigate();
                                                    navigate(`/assets/${row.ApplianceID}`)
                                                } else {
                                                    displayNote && displayNote("Asset Discontiuned", "error");
                                                }
                                            }}>
                                                <TableCell component="th" style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }}>
                                                    {row.AssetNumber}
                                                    <DebugButton data={row} />
                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">
                                                    {row.Type}
                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">
                                                    {row.Make}
                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">
                                                    {row.Model}
                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">
                                                    {row.SerialNumber}

                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">
                                                    {row.Type}

                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">
                                                    {row.Condition}

                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">
                                                    {typeof row.RepairSpend === 'number' && <>  £ {row.RepairSpend.toFixed(2)}</>}
                                                </TableCell>

                                            </TableRow>
                                        } else {
                                            return <></>
                                        }
                                    })}

                                </TableBody>
                                <TableFooter>
                                    <TableCell component="th" scope="row" style={{ borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }}>


                                    </TableCell>
                                    <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">

                                    </TableCell>
                                    <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">

                                    </TableCell>
                                    <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">

                                    </TableCell>
                                    <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">


                                    </TableCell>
                                    <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">

                                    </TableCell>
                                    <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">

                                        Total
                                    </TableCell>
                                    <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">
                                        £ {data.reduce((accumulator, currentValue) => {
                                            return accumulator + currentValue.RepairSpend
                                        }, 0).toFixed(2)}
                                    </TableCell>

                                </TableFooter>
                            </Table>
                        </TableContainer> : <Box><CircularProgress /></Box>}

                    </Grid>

                </Grid>
            </GreyBox>
        </Grid>

    </Grid>
}


export default SiteSpend