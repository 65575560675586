import { Button, ListItemIcon, ListItemText, Menu, MenuItem, } from '@mui/material';

import { GridEventListener, GridMenuIcon, GridRowSelectionModel, GridToolbarContainer, useGridApiContext, useGridApiEventHandler, } from "@mui/x-data-grid";
import { useEffect, useState } from 'react';

import TableSearch from './table-search';
import { iDoWith } from './sqlTableMagic';
import { useSearchParams } from 'react-router-dom';
import useDebouncedValue from './useDebounce';



interface iToolBar {
    combinedSearch: boolean;
    selectionModel?: GridRowSelectionModel[];
    doWithMulti?: iDoWith[],
    data?: any[]
    search: string;
    tblIndex: number;
    setSearch: (d: string) => void;
}


const CustomToolbar = ({ combinedSearch, selectionModel = undefined, data = undefined, doWithMulti = undefined, search, setSearch, tblIndex }: iToolBar) => {

    let [searchParams, setSearchParams] = useSearchParams();
    const [valueTop, setValueTop] = useState<number>(0);
    const [valueLeft, setValueLeft] = useState<number>(0);
    const debouncedSearchTermTop = useDebouncedValue<number>(valueTop, 1000);
    const debouncedSearchTermLeft = useDebouncedValue<number>(valueLeft, 1000);
    const apiRef = useGridApiContext();

    const __SCROLL_LEFT = `x${tblIndex}`
    let scrollLeft = 0;
    if (searchParams.get(__SCROLL_LEFT) !== null) {
        const d = searchParams.get(__SCROLL_LEFT);
        if (d !== null) scrollLeft = parseInt(d)

    }
    // const setScrollLeft = (page: number) => {

    //     //setSearchParams({ page: page.toString() }, { state: { key: "value" } })
    // }

    const __SCROLL_TOP = `y${tblIndex}`
    let scrollTop = 0;
    if (searchParams.get(__SCROLL_TOP) !== null) {
        const d = searchParams.get(__SCROLL_TOP);
        if (d !== null) scrollTop = parseInt(d)

    }
    // const setScrollTop = (page: number) => {
    //     setValue((prev) => {
    //         prev.top = page
    //         return prev
    //     })
    //     //setSearchParams({ page: page.toString() }, { state: { key: "value" } })
    // }



    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //  const whyModal: number[] = (selectionModel !== undefined ? selectionModel.map((d: any) => d) : [])
    return (
        <GridToolbarContainer sx={{ textAlign: 'right', mb: 1 }}>


            {combinedSearch && <TableSearch search={search} setSearch={setSearch} />}

            {selectionModel && selectionModel.length > 0 && <>



                <Button

                    id="basic-button"
                    className="burger-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <GridMenuIcon /> {selectionModel && selectionModel.length}
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {doWithMulti && doWithMulti.map((d, di) => {


                        return <MenuItem key={di} onClick={handleClose}>

                            <ListItemIcon>

                            </ListItemIcon>
                            <ListItemText>
                                Not Implimented
                            </ListItemText>
                        </MenuItem>



                    })}

                </Menu></>}
        </GridToolbarContainer >
    );
}



export default CustomToolbar;