import { Grid } from "@mui/material";
import GreyBox, { alwaysFullSpacing } from "../../util/greyBox";
import SqlTableMagic from "../../util/sqlTableMagic";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

// const GridRow=(props:any)=>{
//     const navigate = useNavigate();
//     return <Box onClick={()=>{
//         navigate && navigate(`/locations/${props.row.CompanyLocationID}`)
//     }}>
//         <Typography variant="h2">{props.row.CompanyLocation1Address}</Typography>
//         <Typography variant="body1">{props.row.CompanyLocationAddress} {props.row.CompanyLocationPostcode}</Typography>
        
//         <Divider />
//     </Box>
// }
interface iLocationsState {

        scrollPosition: number;
}
const Locations = () => {

    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    const uniqueId = "LocationsPg"
    useEffect(() => {



        const loaded = searchParams.get("loaded" + uniqueId)
        if (loaded == null) {
            setSearchParams((prev) => {

                prev.set("loaded" + uniqueId, Date.now().toString())
                // prev.set(__SCROLL_LEFT, debouncedSearchTerm.left.toString())
                return prev
            }, { replace: true })
            setLoaded(true)

        } else {
            const stateT = window.localStorage.getItem(uniqueId + loaded);
            if (stateT !== null) {
                const state = JSON.parse(stateT) as iLocationsState | undefined;
                console.log("STATE", stateT, state)
                if (state && state) {
                   // setQuery(state.query);

                    window.scrollTo(0, state.scrollPosition);
                }
            }
            setLoaded(true)
        }
    }, []);


    const navigateTo = (navigateTo: string) => {
       
        navigate(navigateTo, {});
    };


    return <Grid container spacing={0}>
        <Grid item {...alwaysFullSpacing}>
            <GreyBox>
                <Grid container>

                    <Grid item md={2}>
                        <h2>Locations</h2>
                    </Grid>

                </Grid>
                {loaded && <SqlTableMagic  navigateTo={navigateTo}  title="" url="locations/list" requestType='GET' height="700px"  uniqueId={`locations`}/>}
            </GreyBox>
        </Grid>

    </Grid>
}


export default Locations