import { Box, Button, Grid } from "@mui/material";
import GreyBox, { alwaysFullSpacing } from "../../util/greyBox";
import SqlTableMagic from "../../util/sqlTableMagic";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
interface iQuotesState {
    query: any;
    scrollPosition: number;
}

const Quotes = () => {


     const [query, setQuery] = useState<any | undefined>({ JobQuoteStatus: "Live" });
     const navigate = useNavigate();
     const [loaded, setLoaded] = useState(false);
     let [searchParams, setSearchParams] = useSearchParams();
     const uniqueId = "QuotesPg"
     useEffect(() => {
 
 
 
         const loaded = searchParams.get("loaded" + uniqueId)
         if (loaded == null) {
             setSearchParams((prev) => {
 
                 prev.set("loaded" + uniqueId, Date.now().toString())
                 // prev.set(__SCROLL_LEFT, debouncedSearchTerm.left.toString())
                 return prev
             }, { replace: true })
             setLoaded(true)
 
         } else {
             const stateT = window.localStorage.getItem(uniqueId + loaded);
             if (stateT !== null) {
                 const state = JSON.parse(stateT) as iQuotesState | undefined;
                 console.log("STATE", stateT, state)
                 if (state && state) {
                     setQuery(state.query);
 
                     window.scrollTo(0, state.scrollPosition);
                 }
             }
             setLoaded(true)
         }
     }, []);
     useEffect(() => {
        console.log("EFFECT")
        const thisPageState: iQuotesState = {
            scrollPosition: window.scrollY,
            query
        };
        const loaded = searchParams.get("loaded" + uniqueId)

        window.localStorage.setItem(uniqueId + loaded, JSON.stringify(thisPageState));

    }, [query]);
 
     const navigateTo = (navigateTo: string) => {
        //  const thisPageState: iQuotesState = {
        //      scrollPosition: window.scrollY,
        //         query
        //  };
        // const loaded = searchParams.get("loaded" + uniqueId)
         //window.localStorage.setItem(uniqueId + loaded, JSON.stringify(thisPageState));
        // alert("d")
         navigate(navigateTo, {});
     };
 

    return <Grid container spacing={0}>
        <Grid item {...alwaysFullSpacing}>
            <GreyBox>
                <Grid container>
                    <Grid item md={2}>
                        <h2>Quotes</h2>
                    </Grid>
                    {loaded && <Grid item md={10}>
                        <Box sx={{ textAlign: 'right', paddingTop: 2 }}>
                            <Button onClick={() => {
                                setQuery(undefined)
                            }} className={`${query === undefined && 'activeButton'}`}>
                                All
                            </Button>

                            <Button onClick={() => {
                                setQuery({ JobQuoteStatus: "Live" })
                            }} className={`${query !== undefined && query.JobQuoteStatus === "Live" && 'activeButton'}`}>
                                Live
                            </Button>

                            <Button onClick={() => {
                                setQuery({ JobQuoteStatus: "Accepted" })
                            }} className={`${query !== undefined && query.JobQuoteStatus === "Accepted" && 'activeButton'}`}>
                                Approved
                            </Button>

                            <Button onClick={() => {
                                setQuery({ JobQuoteStatus: "Declined" })
                            }} className={`${query !== undefined && query.JobQuoteStatus === "Declined" && 'activeButton'}`}>
                                Declined
                            </Button>

                            <Button onClick={() => {
                                setQuery({ JobQuoteStatus: "Expired" })
                            }} className={`${query !== undefined && query.JobQuoteStatus === "Expired" && 'activeButton'}`}>
                                Expired
                            </Button>

                            <Button onClick={() => {
                                setQuery({ JobQuoteStatus: "Requote" })
                            }} className={`${query !== undefined && query.JobQuoteStatus === "Requote" && 'activeButton'}`}>
                                Requote
                            </Button>

                        </Box>
                    </Grid>}
                </Grid>


              {loaded && <SqlTableMagic title="" url="quotes/list-live" requestType='GET' height="700px" query={query}  uniqueId={`quotes`} navigateTo={navigateTo}/>}
            </GreyBox>
        </Grid >

    </Grid >
}


export default Quotes