import { HelmetProvider } from "react-helmet-async";
import {
  BrowserRouter
} from "react-router-dom";
import './App.css';
import IndexRoutes from "./pages";
import AuthProvider from "./util/context";
import NotifyProvider from "./util/notify";
import TableProvider from "./util/table-provider";


//wby
const App = () => {
  return <HelmetProvider>
    <AuthProvider>
      <BrowserRouter>
        <NotifyProvider>
          
          <TableProvider>
            <IndexRoutes />
          </TableProvider>
        </NotifyProvider>
      </BrowserRouter>
    </AuthProvider>
  </HelmetProvider>

}

export default App;
