import DownloadIcon from '@mui/icons-material/Download';
import { Button } from "@mui/material";
import axios from "axios";
import { useState } from 'react';
import { useNotify } from "./notify";

interface iDownload {
    buttonText: string;
    className?: string;
    cacheId: any;
    width?:string;
    onClose?: () => void;
    variant?: 'outlined' | 'text';
}

const Download = ({ className = '', buttonText = '', cacheId, onClose, variant = 'outlined', width }: iDownload) => {
    // const [open, setOpen] = useState(false);
    // const handleOpen = () => setOpen(true);
    // const handleClose = () => {
    //     onClose && onClose()
    //     setOpen(false)
    // };

    const [loading, setLoading] = useState<boolean>(false);
    // const isArray = Array.isArray(jobId)
    const { displayNote } = useNotify();


    const DownloadView = async () => {
        setLoading(true);
        console.log("GET", cacheId)

        axios({
            url: `/api/cache/${cacheId}`,
            method: 'GET',
            responseType: 'blob',
        }).then((response: any) => {
            setLoading(false);
            console.log("GET", response.data)
            if (response.data.size > 100) {
                const ffsWHy = response.headers['content-type']
                console.log("GET", response.headers, ffsWHy)
                var file = new Blob([response.data], { type: ffsWHy });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);



                onClose && onClose()
            } else {
                displayNote && displayNote("Cant Find Cache", 'error');
                onClose && onClose()
            }

        });
    }
    const btnSX:any = {};
    if(width){
        btnSX.width = width;
    }

    // const DownloadNow= async () => {
    //     setLoading(true);
    //     console.log("GET", cacheId)

    //     axios({
    //         url: `/api/cache/${cacheId}`,
    //         method: 'GET',
    //         responseType: 'blob',
    //     }).then((response: any) => {
    //         setLoading(false);
    //         console.log("GET", response.data)
    //         if (response.data.size > 100) {
    //             const ffsWHy = response.headers['content-disposition'].split('"')[1]
    //             console.log("GET", response.headers['content-disposition'], response.data, ffsWHy)
    //             const url = window.URL.createObjectURL(new Blob([response.data]));
    //             const link = document.createElement('a');
    //             link.href = url;
    //             link.setAttribute('download', ffsWHy); //or any other extension
    //             document.body.appendChild(link);
    //             link.click();

    //             onClose && onClose()
    //         } else {
    //             displayNote && displayNote("Cant Find Cache", 'error');
    //             onClose && onClose()
    //         }

    //     });
    // }



    return <>{cacheId !== 0 && <><Button sx={btnSX} variant={variant} className={className} onClick={(ev) => {

        ev.stopPropagation();

        DownloadView();
    }}>{loading ? "Loading" : <>{buttonText === '' ? <DownloadIcon /> : buttonText}</>}</Button></>}

    </>
}



export default Download;