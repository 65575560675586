import { ButtonGroup, CircularProgress, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import doRequest from "../../util/axios";
import DisplaySomething from "../../util/displaySomething";
import GreyBox, { alwaysFullSpacing, containerSpacing } from "../../util/greyBox";
import NotFound from "../../util/not-found";
import SqlTableMagic from "../../util/sqlTableMagic";
import TabsThing from "../../util/tabs";
import Requote from "../../util/requote";
import DebugButton from "../../util/debugButton";
import AddNote from "../../util/add-note";
import DownloadIcon from '@mui/icons-material/Download';
import axios from "axios";
import { useNotify } from "../../util/notify";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import CloseIcon from '@mui/icons-material/Close';
interface iDateFromString {
    string: string;
}
interface iState{
    tabIndex:number;
    scrollPosition:number;
}
const DateFromString = ({ string }: iDateFromString) => {

    const d: Date = new Date(Date.parse(string));
 //   let data = '';

    if (!isNaN(Number(d))) {
        let date, month, year;

        date = d.getDate();
        month = d.getMonth() + 1; // take care of the month's number here ⚠️
        year = d.getFullYear();

        return <>{`${date}/${month}/${year}`}</>;

    }

    return <></>;
}
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: "90%",
    width: "75%",
    bgcolor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    overflow: "scroll",
    p: 4,
};
const Job = () => {
    const params = useParams();
    const [job, setJob] = useState<any>();
    const [cachetitle, setCacheTitle] = useState<string>();
    const [cacheId, setCacheId] = useState<string>();
    const [open, setOpen] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);



    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const uniqueId = "JobPg"
    useEffect(() => {
        const loaded = searchParams.get("loaded"+uniqueId)
        if (loaded == null) {
            setSearchParams((prev) => {
                prev.set("loaded"+uniqueId, Date.now().toString())
                // prev.set(__SCROLL_LEFT, debouncedSearchTerm.left.toString())
                return prev
            }, { replace: true })
        } else {
            const stateT = window.localStorage.getItem(uniqueId+loaded);
            if (stateT !== null) {
                const state = JSON.parse(stateT) as iState | undefined;
                console.log("STATE", stateT, state)
                if (state && state) {
                    setTabIndex(state.tabIndex);
                   
                    window.scrollTo(0, state.scrollPosition);
                }
            }
        }
    }, []);

    useEffect(() => {

        const thisPageState: iState = {
            scrollPosition: window.scrollY,
            tabIndex
        };
        const loaded = searchParams.get("loaded"+uniqueId)

        window.localStorage.setItem( uniqueId + loaded, JSON.stringify(thisPageState));

    }, [tabIndex]);

    const navigateTo = (navigateTo: string) => {
        const thisPageState: iState = {
            scrollPosition: window.scrollY,
            tabIndex
        };
        const loaded = searchParams.get("loaded"+uniqueId)
        window.localStorage.setItem( uniqueId + loaded, JSON.stringify(thisPageState));
        navigate(navigateTo, {});
    };


    const handleOpen = (cacheIdd: string, title: string) => {
        setCacheId(cacheIdd)
        setCacheTitle(title)
        setOpen(true);
    }
    const handleClose = () => {
        setCacheId(undefined)
        setCacheTitle(undefined)
        setOpen(false);
    }


    const [tabsInfo, setTabsInfo] = useState<any[]>();
    const [jobPhotos, setJobPhotos] = useState<any[]>([]);
    const [title, setTitle] = useState<string>();
    const mounted = useRef(false);
    const { displayNote } = useNotify();

    const Download = async (cacheId: string) => {

        console.log("GET", cacheId)

        axios({
            url: `/api/cache/${cacheId}`,
            method: 'GET',
            responseType: 'blob',
        }).then((response: any) => {

           // console.log("GET", response.data)
            if (response.data.size > 100) {



                try {
                    const ffsWHy = response.headers['content-disposition'].split('"')[1]
                    console.log("GET", response.headers['content-disposition'], response.data, ffsWHy)
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', ffsWHy); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                } catch (ex) {
                    displayNote && displayNote("Cant Find Cache", 'error');
                }


                // 

            }

        });
    }

    const Go = async () => {
        const response = await doRequest<any>(`jobs/job/${params.id}`, 'GET', {})
        const photos = await doRequest<any>(`jobs/sJobPhotos/${params.id}`, 'GET', {})
        console.log("static", response, response[1][0], photos);

        if (response[0] && response[0].length > 0) {
            setJobPhotos(photos)
            setJob(response[1][0])
            setTabsInfo(response[0])
            setTitle("Job #" + response[1][0].id + " " + response[1][0].JobTitleOneLine + "  (Call Ref: " + response[1][0].JobCallRef + ")")
        } else {
            setJob(null)
            // setMissing(true)
        }

    }


    useEffect(() => {
        if (mounted.current === false) {
            mounted.current = true;
            Go();
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id])

    const reload = () => {
        Go();
    }
    if (job === undefined) {
        return <CircularProgress />
    }

    if (job === null) {
        return <NotFound />
    }

    const onSiteXS = {
        color: "white",
        textAlign: "center",
        backgroundColor: "red",
        paddingTop: 3,
        paddingBottom: 3,
        marginLeft: 1,
        marginRight: 1,
        fontWeight: 800,
    }
    const headSX = {
        paddingLeft: 1,
        paddingBottom: 1
    }
    return <Grid container spacing={1}>
        <Grid item  {...alwaysFullSpacing}>


            <Typography sx={headSX} variant="h3">{title}</Typography>



            {job && job.JobEngineerOnsite !== null && job.JobEngineerOnsite !== undefined && <Box sx={onSiteXS}>Enginer Onsite <DateFromString string={job.JobEngineerOnsite} /></Box>}
            
            
            {job && job.JobAwaitingPartsYN !== null && job.JobAwaitingPartsYN !== undefined &&  job.JobAwaitingPartsYN === true &&  <Box sx={onSiteXS}>Currently awaiting delivery of parts<DateFromString string={job.JobEngineerOnsite} /></Box>}
            
            
            
            <Grid item container  {...alwaysFullSpacing} spacing={0}>
                <Grid item  {...containerSpacing}>
                    <GreyBox>

                        <Typography sx={headSX} variant="h4">Site Details</Typography>
                        <DisplaySomething navigateTo={navigateTo} payload={job} field="JobTitleOneLine" text="Site Name" type="siteName" size={12} />
                        <DisplaySomething navigateTo={navigateTo} payload={job} field="CompanyLocationAddress" text="Address" size={12} />
                        <DisplaySomething navigateTo={navigateTo} payload={job} field="CompanyLocationPostcode" text=" Postcode" size={12} />

                    </GreyBox>
                    <GreyBox>
                        <Typography sx={headSX} variant="h4">Account Manager Details</Typography>
                        <DisplaySomething navigateTo={navigateTo} payload={job} field="AccountManager" text="Name" size={12} />
                        <DisplaySomething navigateTo={navigateTo} payload={job} type="email" field="AccountManagerEmail" text="Email" size={12} />
                        <DisplaySomething navigateTo={navigateTo} payload={job} type="phone" field="AccountManagerPhone" text="Phone" size={12} />
                    </GreyBox>
                </Grid>
                <Grid item  {...containerSpacing}>
                    <GreyBox>
                        <Typography sx={headSX} variant="h4">Job Details</Typography>
                        <Grid container spacing={0} sx={{ paddingTop: 2, paddingLeft: 4, paddingRight: 4, textAlign: "center" }}>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <AddNote object={job} jobId={job.id} onClose={reload} />
                            </Grid>

                        </Grid>

                        <DisplaySomething navigateTo={navigateTo} payload={job} field="JobCallRef" text="Call Ref" size={12} />
                        <DisplaySomething navigateTo={navigateTo} payload={job} field="JobType" text="Type" size={12} />
                        <DisplaySomething navigateTo={navigateTo} payload={job} field="JobFaultReportedOneLine" size={12} text="Details" />
                        <DisplaySomething navigateTo={navigateTo} payload={job} field="StatusType" text="Job Status" size={12} />
                        <DisplaySomething navigateTo={navigateTo} payload={job} type={"date"} field="JobRaisedDate" text="Raised Date" size={12} />
                        <DisplaySomething navigateTo={navigateTo} payload={job} type={"date"} field="JobJobDate" text="Job Date" size={12} />
                        {job.JobAttendedDate && <DisplaySomething navigateTo={navigateTo} payload={job} type={"date"} field="JobAttendedDate" text="Attended Date" size={12} />}
                        {job.JobStartTime && <DisplaySomething navigateTo={navigateTo} payload={job} field="JobStartTime" text="Job Start" type="time" size={12} />}
                        {job.JobEndTime && <DisplaySomething navigateTo={navigateTo} payload={job} field="JobEndTime" text="Job End" type="time" size={12} />}
                        {job.JobRelatedID !== 0 && <DisplaySomething navigateTo={navigateTo} payload={job} field="JobRelatedID" text="Related Job" type="job" size={12} />}
                        {job.JobRelatedPreviousID !== 0 && <DisplaySomething navigateTo={navigateTo} payload={job} field="JobRelatedPreviousID" text="Job Related Previous" type="job" size={12} />}

                    </GreyBox>

                    <GreyBox>
                        <Typography sx={headSX} variant="h4">Quote Details</Typography>
                        {job.JobRequoteYN === true &&
                            <Grid container spacing={0} sx={{ paddingTop: 2, paddingLeft: 4, paddingRight: 4, textAlign: "center" }}>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Requote object={job} jobId={job.id} onClose={reload} />
                                </Grid>

                            </Grid>}
                        {job.JobQuoteStatus && <DisplaySomething navigateTo={navigateTo} payload={job} field="JobQuoteStatus" text="Quote Status" size={12} />}
                        {job.JobQuotedDate && <DisplaySomething navigateTo={navigateTo} payload={job} type={"date"} field="JobQuotedDate" text="Quoted Date" size={12} />}
                        {job.JobQuoteAcceptedDate && <DisplaySomething navigateTo={navigateTo} payload={job} type={"date"} field="JobQuoteAcceptedDate" text="Customer Quote Accepted Date" size={12} />}
                        {job.JobQuotePONumber && <DisplaySomething  navigateTo={navigateTo} payload={job} field="JobQuotePONumber" text="Your PO Number" size={12} />}
                        {job.JobQuoteNumber !== 0 && job.JobQuoteNumber && <DisplaySomething navigateTo={navigateTo} payload={job} field="JobQuoteNumber" text="Quote" type="quote" size={12} />}
                    </GreyBox>
                </Grid>


            </Grid>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <ButtonGroup sx={{ position: 'absolute', right: 20 }}>

                        <IconButton
                            onClick={(ev) => {
                                ev.preventDefault();
                                handleClose()
                            }}
                            aria-label={`Close`}
                        >
                            <CloseIcon />
                        </IconButton>
                        <IconButton
                            onClick={(ev) => {
                                ev.preventDefault();
                                cacheId && Download(cacheId);
                            }}
                            aria-label={`Download`}
                        >
                            <DownloadIcon />
                        </IconButton>
                    </ButtonGroup>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {cachetitle}


                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2, textAlign: "center" }}>
                        <Box
                            component="img"
                            sx={{
                                width: "80%",
                                //  maxHeight: { xs: 233, md: 167 },
                                //   maxWidth: { xs: 350, md: 250 },
                            }}
                            alt={""}
                            src={`/api/cache/${cacheId}`}
                        />
                    </Typography>

                </Box>
            </Modal>

            <Grid item  {...alwaysFullSpacing}>
                {jobPhotos.length > 0 && <GreyBox >

                    <ImageList
                        sx={{

                            gridAutoFlow: "column",
                            gridTemplateColumns: `repeat(${jobPhotos.length}, 400px) !important`,
                          
     
                        }}
                    >


                        {jobPhotos.map((item) => (
                            <ImageListItem sx={{


                                height: 200,
                            }}>

                                <Box
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        handleOpen(item.CacheID, item.CacheFileTitle);
                                    }}
                                    component="img"
                                    sx={{
                                        width: "90%"

                                    }}
                                    alt={item.CacheFileTitle}
                                    src={`/api/cache/${item.CacheID}?thumb=true`}
                                />

                                <ImageListItemBar
                                    title={item.CacheFileTitle}
                                    subtitle={""}
                                    actionIcon={
                                        <IconButton
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                handleOpen(item.CacheID, item.CacheFileTitle);
                                            }}
                                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                            aria-label={`${item.CacheFileTitle}`}
                                        >
                                            <ZoomInIcon />
                                        </IconButton>
                                    }
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>

                </GreyBox>}



                <DebugButton data={job} />
                {tabsInfo !== undefined && <Grid item  {...alwaysFullSpacing}>
                    <GreyBox>
                        <TabsThing
                            tabIndex={tabIndex}
                            setTabIndex={setTabIndex}
                            tabs={tabsInfo.map((ti, tii) => {
                            return { title: ti.FN, content: <SqlTableMagic  navigateTo={navigateTo} combinedSearch={false} title={''} url={`jobs/${ti.LF}/${params.id}`} requestType='GET' tblIndex={tii}  uniqueId={`job_${tii}`} /> }
                        })} />

                    </GreyBox>
                </Grid>}
            </Grid>
        </Grid >
    </Grid>
}


export default Job