import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import doRequest from "./axios";
import { useAuth } from "./context";
import { useNotify } from "./notify";
import { useTableContext } from "./table-provider";


interface iApprove {
    jobId: any;
    object: any;
    width?: string;
    className?: string;
    onClose: () => void;
    variant?: 'outlined' | 'text';
}

export interface iSimpleDialogProps {
    open: boolean;
    onCancel: () => void;
    onClose: (value: string) => void;
}

const SimpleDialog = (props: iSimpleDialogProps) => {
    const { onClose, onCancel, open } = props;
    const auth = useAuth();
    const [feedback, setFeedback] = useState('');

    const handleClose = () => {

        if (auth && auth.CompanyPONumberRequiredYN === true) {
            if (text !== '') {
                onClose(text);
            } else {
                setFeedback("Please set a PO Reference")
            }
        } else {
            onClose(text);
        }



    };

    const [text, setText] = useState('');
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };


    return (
        <Dialog onClose={handleClose} fullWidth open={open}>
            <DialogTitle>Approve</DialogTitle>
            <DialogContent>

                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    value={text}
                    onChange={handleChange}
                    label="PO Number"
                    type="text"
                    fullWidth
                    variant="standard"
                />

                {feedback !== '' && <>{feedback}</>}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Cancel</Button>
                <Button onClick={handleClose}>Approve</Button>
            </DialogActions>

        </Dialog>
    );
}



const Approve = ({ className = '', object, jobId, onClose, variant = 'outlined', width }: iApprove) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const location = useLocation();
    const { displayNote } = useNotify();
    const { trigger } = useTableContext()
    const handleClose = () => {
        //  onClose && onClose()
        setOpen(false)
    };
    const auth = useAuth();
    const btnSX: any = {};
    if (width) {
        btnSX.width = width;
    }
    const approveThis = async (text: string) => {
        setLoading(true)
        try {
            handleClose();
            const response = await doRequest<any>(`jobs/approveQuote/${jobId}`, 'POST', { approve: true, poNumber: text })
            console.log("static", response, location.pathname)
            if (response.X === 'OK') {
                console.log("static", response)
                displayNote && displayNote('You have approved a quote', 'success')


                if (location.pathname.includes('/quote')) {
                    trigger && trigger('quotes/list-approved');
                } else {
                    trigger && trigger('jobs/sHomeCompleteJob')

                }
                onClose()
                setLoading(false)
            } else {
                displayNote && displayNote('Not valid', 'error')
                setLoading(false)
            }
        } catch {
            displayNote && displayNote('An Error occurred', 'error')
            setLoading(false)
        }
    }

    // const isArray = Array.isArray(jobId)
    const text = (object.JobQuoteStatusID === -2 ? `£ ${auth && auth.PortalUserLimit && auth.PortalUserLimit} Limit` : 'Approve')
    return <div>
        <SimpleDialog

            open={open}
            onClose={approveThis}
            onCancel={handleClose}
        />
        <Button sx={btnSX} className={className} variant={variant} disabled={loading || object.JobQuoteStatusID === -2} onClick={async () => {
            handleOpen();
        }}>{loading ? "Loading" : text}</Button>


    </div>
}



export default Approve;