import { Box, Button, Grid } from "@mui/material";
import GreyBox, { alwaysFullSpacing } from "../../util/greyBox";
import SqlTableMagic from "../../util/sqlTableMagic";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";


interface iAssetsState {
    query: any;
    scrollPosition: number;
}

const Assets = () => {
    const [query, setQuery] = useState<any | undefined>(undefined);
    
    
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    const uniqueId = "AssetsPg"
    useEffect(() => {



        const loaded = searchParams.get("loaded" + uniqueId)
        if (loaded == null) {
            setSearchParams((prev) => {

                prev.set("loaded" + uniqueId, Date.now().toString())
                // prev.set(__SCROLL_LEFT, debouncedSearchTerm.left.toString())
                return prev
            }, { replace: true })
            setLoaded(true)

        } else {
            const stateT = window.localStorage.getItem(uniqueId + loaded);
            if (stateT !== null) {
                const state = JSON.parse(stateT) as iAssetsState | undefined;
                console.log("STATE", stateT, state)
                if (state && state) {
                    setQuery(state.query);

                    window.scrollTo(0, state.scrollPosition);
                }
            }
            setLoaded(true)
        }
    }, []);

    useEffect(() => {

        const thisPageState: iAssetsState = {
            scrollPosition: window.scrollY,
            query
        };
        const loaded = searchParams.get("loaded" + uniqueId)

        window.localStorage.setItem(uniqueId + loaded, JSON.stringify(thisPageState));

    }, [query]);
    const navigateTo = (navigateTo: string) => {
        navigate(navigateTo, {});
    };



    return <Grid container spacing={0}>
        <Grid item {...alwaysFullSpacing}>
            <GreyBox>
                <Grid container>

                    <Grid item md={2}>
                        <h2>Assets</h2>
                    </Grid>
                    {loaded && <Grid item md={10}>
                        <Box sx={{ textAlign: 'right', paddingTop: 2 }}>
                            <Button onClick={() => {
                                setQuery(undefined)
                            }} className={`${query === undefined && 'activeButton'}`}>
                                All
                            </Button>

                            <Button onClick={() => {
                                setQuery({ ApplianceChecklistConditionRating: "1. Not working" })
                            }} className={`${query !== undefined && query.ApplianceChecklistConditionRating === "1. Not working" && 'activeButton'}`}>
                                Not Working / Replace
                            </Button>
                        </Box>
                    </Grid>}
                </Grid>

         
                {loaded && <SqlTableMagic title="" url="assets/list" requestType='GET' height="700px" query={query} uniqueId="assets" navigateTo={navigateTo} />}

            </GreyBox>
        </Grid>

    </Grid>
}


export default Assets