import { CircularProgress, Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import doRequest from "../../util/axios";
import DataDump from "../../util/dataDump";
import DisplaySomething from "../../util/displaySomething";
import NotFound from "../../util/not-found";
interface iState {

    scrollPosition: number;
}
const Repair = () => {

    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const uniqueId = "LocationPg"
    useEffect(() => {
        const loaded = searchParams.get("loaded" + uniqueId)
        if (loaded == null) {
            setSearchParams((prev) => {
                prev.set("loaded" + uniqueId, Date.now().toString())
                // prev.set(__SCROLL_LEFT, debouncedSearchTerm.left.toString())
                return prev
            }, { replace: true })
        } else {
            const stateT = window.localStorage.getItem(uniqueId + loaded);
            if (stateT !== null) {
                const state = JSON.parse(stateT) as iState | undefined;
                console.log("STATE", stateT, state)
                if (state && state) {

                    window.scrollTo(0, state.scrollPosition);
                }
            }
        }
    }, []);



    const navigateTo = (navigateTo: string) => {
        const thisPageState: iState = {
            scrollPosition: window.scrollY,

        };
        const loaded = searchParams.get("loaded" + uniqueId)
        window.localStorage.setItem(uniqueId + loaded, JSON.stringify(thisPageState));
        navigate(navigateTo, {});
    };




    const params = useParams();
    const [repair, setRepair] = useState<any>();
    const mounted = useRef(false)
    useEffect(() => {
        const Go = async () => {
            const response = await doRequest<any>(`repair/repair/${params.id}`, 'GET', {})
            console.log("static", response)
            if (response.length !== 0) {
                setRepair(response[0]);
            } else {
                setRepair(null)
            }
        }

        if (mounted.current === false) {
            mounted.current = true;
            Go();
        }

    }, [params.id])


    if (repair === undefined) {
        return <CircularProgress />
    }
    if (repair === null) {
        return <NotFound />
    }
    return <Grid container spacing={2}>


        <DisplaySomething navigateTo={navigateTo} payload={repair} field="JobApplianceRepairDate" text="Repair Date" type="date" />
        <DisplaySomething navigateTo={navigateTo} payload={repair} field="JobApplianceRepairID" text="JobApplianceRepairID" />
        <DisplaySomething navigateTo={navigateTo} payload={repair} field="JobID" text="JobID" type="job" />
        <DisplaySomething navigateTo={navigateTo} payload={repair} field="ApplianceID" text="ApplianceID" type="asset" />

        <DisplaySomething navigateTo={navigateTo} payload={repair} field="JobApplianceNote" text="Note" />
        <DisplaySomething navigateTo={navigateTo} payload={repair} field="JobAppliancePartsUsed" text="Appliance Parts Used" />
        <DisplaySomething navigateTo={navigateTo} payload={repair} field="JobAppliancePartsRequired" text="JobAppliancePartsRequired" />
        <DisplaySomething navigateTo={navigateTo} payload={repair} field="JobApplianceRepairIncome" text="Repair Income" />
        <DisplaySomething navigateTo={navigateTo} payload={repair} field="ApplianceType" text="Type" />
        <DisplaySomething navigateTo={navigateTo} payload={repair} field="ApplianceDescription" text="Description" />
        <DisplaySomething navigateTo={navigateTo} payload={repair} field="ApplianceAssetNumber" text="Asset No" />
        <DisplaySomething navigateTo={navigateTo} payload={repair} field="ApplianceManufacturer" text="Manufacturer" />
        <DisplaySomething navigateTo={navigateTo} payload={repair} field="ApplianceModel" text="Model" />
        <DisplaySomething navigateTo={navigateTo} payload={repair} field="ApplianceSerialNumber" text="Serial No" />




        <Grid item md={12}>
            <DataDump data={repair} />
        </Grid>


    </Grid>
}


export default Repair