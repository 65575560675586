import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Field, FormSpy } from "react-final-form";
import { composeValidators, requiredValidate } from "./validators";

interface iAutoCompleteSelect {
    name: string;
    label: string;
    required?: boolean;
    options:any[];
    newObject:any;
    
    disabled?:boolean
    getOptionKey:(value:any)=>string;
    onChange?:(value:any)=>boolean
    getOptionLabel:(value:any)=>string;
}
const AutoCompleteSelect = ({ name, label, options, getOptionKey, getOptionLabel,newObject, onChange, disabled=false, required=false}: iAutoCompleteSelect) => {

    const [inputValue, setInputValue] = useState('');
    let fieldProps: any = {}
    if(required){
        fieldProps.validate = composeValidators(requiredValidate)
    }
    return <FormSpy subscription={{
        values: true,
        dirtySinceLastSubmit: true,
        submitting: true,
    }}>
    {({ dirtySinceLastSubmit, submitting }) => {
        
        
        
        return <Field name={name} {...fieldProps}>
            {props => (
                <div>
                    <Autocomplete
                        value={props.input.value}
                        disabled={disabled}
                        isOptionEqualToValue={(option, value) => {
                            if(option && option !== null){
                                return option.id === value.id
                            } else {
                                return false
                            }
                            
                        }}
                        onChange={(event: any, newValue: string | null, reason) => {
                            console.log(newValue)
                            if(onChange){
                                const oktochange = onChange(newValue)
                                if(oktochange===true){
                                    props.input.onChange(newValue)

                                }
                            } else {
                                props.input.onChange(newValue)

                            }
                        }}
                        color="error"
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                 
                        getOptionLabel={getOptionLabel}
                        id={name}
                        options={[newObject, ...options]}
                        sx={{ }}
                        getOptionKey={getOptionKey}
                        renderInput={(params) => <TextField {...params} label={label} />}

                    />
                   
                    {props.meta.error && <Typography color={"error"} sx={{pl:2}}>Required</Typography>}
                
                </div>  
            )}
        </Field>
    }}
    </FormSpy>

        
}


export default AutoCompleteSelect;