import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import GreyBox, { alwaysFullSpacing } from "../../util/greyBox";
import SqlTableMagic from "../../util/sqlTableMagic";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

// const GridRowJobs=(props:any)=>{
//     const navigate = useNavigate();
//     return <Box onClick={()=>{
//         navigate && navigate(`/jobs/${props.row.JobID}`)
//     }}>
//         <Typography variant="h2">{props.row.JobID} {props.row.AccountManagerEmail}</Typography>
//         <Typography variant="body1">{props.row.CompanyLocationAddress} {props.row.CompanyLocationPostcode}</Typography>

//         <Divider />
//     </Box>
// }


interface iJobsState {
    query: any;
    scrollPosition: number;
}

const Jobs = () => {

    const [query, setQuery] = useState<any | undefined>({ StatusTypeFilter: "Live" });



    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    const uniqueId = "JobsPg"
    useEffect(() => {



        const loaded = searchParams.get("loaded" + uniqueId)
        if (loaded == null) {
            setSearchParams((prev) => {

                prev.set("loaded" + uniqueId, Date.now().toString())
                // prev.set(__SCROLL_LEFT, debouncedSearchTerm.left.toString())
                return prev
            }, { replace: true })
            setLoaded(true)

        } else {
            const stateT = window.localStorage.getItem(uniqueId + loaded);
            if (stateT !== null) {
                const state = JSON.parse(stateT) as iJobsState | undefined;
                console.log("STATE", stateT, state)
                if (state && state) {
                    setQuery(state.query);

                    window.scrollTo(0, state.scrollPosition);
                }
            }
            setLoaded(true)
        }
    }, []);

    useEffect(() => {

        const thisPageState: iJobsState = {
            scrollPosition: window.scrollY,
            query
        };
        const loaded = searchParams.get("loaded" + uniqueId)

        window.localStorage.setItem(uniqueId + loaded, JSON.stringify(thisPageState));

    }, [query]);
    const navigateTo = (navigateTo: string) => {
        // const thisPageState: iJobsState = {
        //     scrollPosition: window.scrollY,
        //     query
        // };
        // const loaded = searchParams.get("loaded" + uniqueId)
        // window.localStorage.setItem(uniqueId + loaded, JSON.stringify(thisPageState));
        navigate(navigateTo, {});
    };



    return <Grid container spacing={0}>
        <Grid item  {...alwaysFullSpacing}>
            <GreyBox>
                <Grid container>

                    <Grid item md={2}>
                        <h2>Jobs</h2>
                    </Grid>
                    <Grid item md={10}>
                        {loaded && <Box sx={{ textAlign: 'right', paddingTop: 2 }}>
                            <Button onClick={() => {
                                setQuery(undefined)
                            }} className={`${query === undefined && 'activeButton'}`}>
                                All
                            </Button>

                            <Button onClick={() => {
                                setQuery({ StatusTypeFilter: "Live" })
                            }} className={`${query !== undefined && query.StatusTypeFilter === "Live" && 'activeButton'}`}>
                                Live
                            </Button>

                            <Button onClick={() => {
                                setQuery({ StatusTypeFilter: "Complete" })
                            }} className={`${query !== undefined && query.StatusTypeFilter === "Complete" && 'activeButton'}`}>
                                Complete
                            </Button>
                        </Box>}
                    </Grid>
                </Grid>
                {loaded && <SqlTableMagic title="" url="jobs/list" requestType='GET' height="700px" query={query} uniqueId={`assetjobs`} navigateTo={navigateTo} />}
            </GreyBox>
        </Grid>
    </Grid>
}

export default Jobs