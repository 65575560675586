import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { Field, Form } from 'react-final-form';


interface iTableSearch {
    search: string;
    setSearch: (d: string) => void
}


const TableSearch = ({ search, setSearch }: iTableSearch) => {


    return <Form
        initialValues={{query:search}}
        subscription={{ submitting: true, pristine: true, values: true }}
        onSubmit={async (d: any) => {
            console.log("D", d)

            setSearch(d.query)
            // 
            // setLoading(false)
        }}

        render={({
            handleSubmit,
            form,
            // pristine,
            values,
            // submitting,
            // invalid,
            // dirtySinceLastSubmit,
        }) => {


            return <Paper

                component="form"
                autoComplete="off"
                onSubmit={handleSubmit}
                noValidate
                sx={{ display: 'flex', alignItems: 'center', width: "100%" }}
            >
                <Field name={'query'} >
                    {({ input: { onChange, value }, meta }) => {



                        return <InputBase
                            size='small'
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search All"
                            id="txtInpCustomerSearch"
                            value={value}
                            onChange={onChange}
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    }}
                </Field>

                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                {values.query && values.query.length > 0 && <IconButton id="btnCancelSearch" type="button" sx={{ p: '10px' }} aria-label="search" color='secondary' onClick={() => {
                    form.change("query", "");
                    setSearch('');
                }}>
                    <CancelIcon />
                </IconButton>}
                <IconButton id="btnTableSearch" type="submit" sx={{ p: '10px' }} aria-label="search" color='secondary' size='small'>
                    <SearchIcon />
                </IconButton>




            </Paper>


        }}
    />
}


export default TableSearch;