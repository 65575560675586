
import { CircularProgress, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import doRequest from "../../util/axios";
import DataDump from "../../util/dataDump";
import DisplaySomething from "../../util/displaySomething";
import Download from "../../util/download";
import GreyBox, { alwaysFullSpacing, containerSpacing } from "../../util/greyBox";
import NotFound from "../../util/not-found";
import SqlTableMagic from "../../util/sqlTableMagic";
import TabsThing from "../../util/tabs";
import DebugButton from "../../util/debugButton";
import CacheThumnail from "../../util/cache-thumbnail";
interface iState{
    tabIndex:number;
    scrollPosition:number;
}
const Asset = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const params = useParams();
    const [asset, setAsset] = useState<any>();
    const [tabsInfo, setTabsInfo] = useState<any[]>();
    const [title, setTitle] = useState<string>();
    const mounted = useRef(false);

    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const uniqueId = "AssetPg"
    useEffect(() => {
        const loaded = searchParams.get("loaded"+uniqueId)
        if (loaded == null) {
            setSearchParams((prev) => {
                prev.set("loaded"+uniqueId, Date.now().toString())
                // prev.set(__SCROLL_LEFT, debouncedSearchTerm.left.toString())
                return prev
            }, { replace: true })
        } else {
            const stateT = window.localStorage.getItem(uniqueId+loaded);
            if (stateT !== null) {
                const state = JSON.parse(stateT) as iState | undefined;
                console.log("STATE", stateT, state)
                if (state && state) {
                    setTabIndex(state.tabIndex);
                   
                    window.scrollTo(0, state.scrollPosition);
                }
            }
        }
    }, []);

    useEffect(() => {

        const thisPageState: iState = {
            scrollPosition: window.scrollY,
            tabIndex
        };
        const loaded = searchParams.get("loaded"+uniqueId)

        window.localStorage.setItem( uniqueId + loaded, JSON.stringify(thisPageState));

    }, [tabIndex]);

    const navigateTo = (navigateTo: string) => {
        const thisPageState: iState = {
            scrollPosition: window.scrollY,
            tabIndex
        };
        const loaded = searchParams.get("loaded"+uniqueId)
        window.localStorage.setItem( uniqueId + loaded, JSON.stringify(thisPageState));
        navigate(navigateTo, {});
    };



    useEffect(() => {
        const Go = async () => {
            const response = await doRequest<any>(`assets/asset/${params.id}`, 'GET', {})
            console.log("static", response)
            if (response[0] && response[0].length > 0) {
                setAsset(response[1][0]);
                setTitle(response[1][0].CompanyLocation1Address + " (" + response[1][0].ApplianceType + ")")
                setTabsInfo(response[0])
            } else {
                setAsset(null)
            }
        }
        if(mounted.current === false){
            mounted.current = true;
            Go();
        }
      
    }, [params.id])



    

    if (asset === undefined) {
        return <CircularProgress />
    }
    if (asset === null) {
        return <NotFound />
    }

    const headSX = {
        paddingLeft: 1
    }


    return <Grid container spacing={0}>
        <Grid item  {...alwaysFullSpacing}>
            <Typography sx={headSX} variant="h3">{title}</Typography>

            <Grid container spacing={0}>
                <Grid item container {...alwaysFullSpacing} spacing={0}>
                    <Grid  {...containerSpacing}>
                        <GreyBox>
                            <Typography sx={headSX} variant="h4">Site Details</Typography>
                            <DisplaySomething navigateTo={navigateTo} payload={asset} field="CompanyLocation1Address" text="Site Name" type="siteName" size={12}  />
                            <DisplaySomething navigateTo={navigateTo} payload={asset} field="CompanyLocationAddress" text="Address" size={12} />
                            <DisplaySomething navigateTo={navigateTo} payload={asset} field="CompanyLocationPostcode" text=" Postcode" size={12} />
                           

                        </GreyBox>
                        {asset.ApplianceHideServiceYN === false && <GreyBox>
                            <Typography sx={headSX} variant="h4">Service</Typography>
                            {asset.LastCheck_Date && <DisplaySomething navigateTo={navigateTo} payload={asset} field="LastCheck_Date" text="Serviced Date" type="date" size={12} />}
                            {asset.NextCheck_Date && <DisplaySomething navigateTo={navigateTo} payload={asset} field="NextCheck_Date" text="Service Due" type="date" size={12} />}

                            {asset.ApplianceChecklistConditionRating && <DisplaySomething navigateTo={navigateTo} payload={asset} field="ApplianceChecklistConditionRating" text="Condition Rating" size={12} />}

                            {asset.LastChecklistCacheID !== 0 && <Box sx={{ textAlign: 'center', paddingBottom: 2 }}>
                                <Download buttonText="Download" cacheId={asset.LastChecklistCacheID} />
                            </Box>}

                        </GreyBox>}
                    </Grid>
                    <Grid item  {...containerSpacing}>
                        <GreyBox className={`Div${asset.Appliance_Background}`}>
                            <Typography sx={headSX} variant="h4">Asset Details</Typography>
                            {asset.ApplianceType && <DisplaySomething navigateTo={navigateTo} payload={asset} field="ApplianceType" text="Type" size={12} />}
                            {asset.ApplianceAssetNumber && <DisplaySomething navigateTo={navigateTo} payload={asset} field="ApplianceAssetNumber" text="Asset Number" size={12} />}
                            {asset.ApplianceDescription && <DisplaySomething navigateTo={navigateTo} payload={asset} field="ApplianceDescription" text="Description" size={12} />}
                            {asset.ApplianceManufacturer && <DisplaySomething navigateTo={navigateTo} payload={asset} field="ApplianceManufacturer" text="Manufacturer" size={12} />}
                            {asset.ApplianceModel && <DisplaySomething navigateTo={navigateTo} payload={asset} field="ApplianceModel" text="Model" size={12} />}
                            {asset.ApplianceSerialNumber && <DisplaySomething navigateTo={navigateTo} payload={asset} field="ApplianceSerialNumber" text="Serial Number" size={12} />}
                            <DisplaySomething navigateTo={navigateTo} payload={asset} field="RepairCost" text="12 Month Repair Costs" type="money" size={12}   />
                            {asset.ApplianceWarrantyPeriodEndDate !== null && <DisplaySomething navigateTo={navigateTo} payload={asset} field="ApplianceWarrantyPeriodEndDate" text="Warranty Period End Date" size={12} type="date"/>}
                            {asset.AppliancePowerSupply !== null && <DisplaySomething navigateTo={navigateTo} payload={asset} field="AppliancePowerSupply" text="Power Supply Phase" size={12} />}
                            {asset.AppliancePowerSupplyAmps !== null && <DisplaySomething navigateTo={navigateTo} payload={asset} field="AppliancePowerSupplyAmps" text="Power Supply Amps" size={12} />}
                            {asset.ApplianceRefrigerant !== null && <DisplaySomething navigateTo={navigateTo} payload={asset} field="ApplianceRefrigerant" text="Refrigerant" size={12} />}

                            <Grid container spacing={0}>
                                <Grid item xs={6}>
                                    <CacheThumnail title="Plate" cacheId={asset.AppliancePlateCacheID} />
                                </Grid>
                                <Grid item xs={6}>
                                    <CacheThumnail title="Appliance" cacheId={asset.ApplianceUnitCacheID} />
                                </Grid>
                            </Grid>
                        </GreyBox>

                        {asset.CompanyHideGasCertificateYN === false && <GreyBox>
                            <Typography sx={headSX} variant="h4">Gas Certificate</Typography>
                            {asset.LastGas_Date && <DisplaySomething navigateTo={navigateTo} payload={asset} field="LastGas_Date" text="Gas Certificate Date" type="date" size={12} />}
                            {asset.NextGas_Date && <DisplaySomething navigateTo={navigateTo} payload={asset} field="NextGas_Date" text="Gas Certificate Due" type="date" size={12} />}

                            {asset.LastGas_Warning && <DisplaySomething navigateTo={navigateTo} payload={asset} field="LastGas_Warning" text="Gas Certificate Warning" size={12} />}

                            {asset.LastGas_GasCertCacheID !== 0 && <Box sx={{ textAlign: 'center', paddingBottom: 2 }}>
                                <Download buttonText="Download Certificate" cacheId={asset.LastGas_GasCertCacheID} />
                            </Box>}
                        </GreyBox>}
                        
                        {asset.ApplianceHideCoffeePressureYN === false && <GreyBox>
                            <Typography sx={headSX} variant="h4">Coffee Machine Pressure Test</Typography>
                            {asset.LastPressure_Date && <DisplaySomething  navigateTo={navigateTo} payload={asset} field="LastPressure_Date" text="Pressure Test Date" type="date" size={12} />}
                            {asset.NextPressure_Date && <DisplaySomething  navigateTo={navigateTo} payload={asset} field="NextPressure_Date" text="Pressure Test Due" type="date" size={12} />}
                            {asset.LastPressureCacheID !== 0 && <Box sx={{ textAlign: 'center', paddingBottom: 2 }}>
                                <Download buttonText="Download Certificate" cacheId={asset.LastPressureCacheID} />
                            </Box>}
                        </GreyBox>}
                    </Grid>
                </Grid>
            </Grid>
            <DebugButton data={asset} />
            {
                tabsInfo !== undefined && <Grid item {...alwaysFullSpacing}>
                    <GreyBox>
                        <TabsThing tabIndex={tabIndex} setTabIndex={setTabIndex} tabs={tabsInfo.map((ti, tii) => {
                            return { title: ti.FN, content: <SqlTableMagic navigateTo={navigateTo} combinedSearch={false} title={''} url={`assets/${ti.LF}/${params.id}`} requestType='GET' tblIndex={tii} uniqueId={`asset_${tii}`}/> }
                        })} />
                    </GreyBox>
                </Grid>
            }

            <DataDump data={asset} />


        </Grid>
    </Grid >
}


export default Asset