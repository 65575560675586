import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { Button, CircularProgress, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import { Box, useMediaQuery, } from '@mui/system';
import { DataGrid, GridColDef, GridEventListener, GridRenderCellParams, GridRowParams, GRID_CHECKBOX_SELECTION_COL_DEF, MuiEvent, useGridApiRef } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { tableSchemeAndData } from "../interfaces/tableAndSchema";
import Approve from './approve';
import doRequest from './axios';
import BurgerMenu from './burger-menu';
import { useAuth } from './context';
import DebugButton from "./debugButton";
import Decline from './decline';
import Download from './download';
import ErrorBoundary from './error-boundry';
import MissingRows from './sqlTableMagic-missing-rows';
import { useTableContext } from './table-provider';
import CustomToolbar from './sqlTableMagic-CustomToolbar';
import SimpleDialog from './simple-dialogue';
import SqlTableMagicMobile from './sqlTableMagic-Mobile';



export interface iDoWith {
    title: string,
    func: string
}
interface iSqlTableMagic {
    url: string,
    combinedSearch?: boolean;
    title: string,
    requestType: 'GET' | 'POST'
    height?: string;
    doWithMulti?: iDoWith[];
    tblIndex?: number;
    customMobileRow?: any;
    query?: any;
    internalQuery?: boolean
    uniqueId: string
    navigateTo: (url: string) => void
}

interface SqlMagicState {
    pageSize: number;
    page: number;
    search: string;
    sortModel: any[];
    selectionModel: any;
    scrollPosition: number;
}
// const [page, setPage] = useState<number>(0);
// const [search, setSearch] = useState<string>("");
// const [sortModel, setSortModel] = useState<any>([{ field: 'id', sort: 'desc' }]);
// const [selectionModel, setSelectionModel] = useState<any>({});
// const [pageSize, setPageSize] = useState<number>(10);

const SqlTableMagic = ({ combinedSearch = true, title, url, requestType, height, doWithMulti, query, customMobileRow, tblIndex = 0, uniqueId, navigateTo }: iSqlTableMagic) => {
    let [searchParams, setSearchParams] = useSearchParams();
    const apiRef = useGridApiRef();
    const matches = useMediaQuery('(max-width:600px)');
    const location = useLocation();
    const navigate = useNavigate();
    const [query1, setQuery1] = useState<any | undefined>();
    const multi = (doWithMulti === undefined ? false : true)
    const { triggerUrl, trigger } = useTableContext()

    const [outputPayload, setOutputPayload] = useState<any[]>([])
    const { logOut } = useAuth();
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [search, setSearch] = useState<string>("");
    const [sortModel, setSortModel] = useState<any>([{ field: 'id', sort: 'desc' }]);
    const [selectionModel, setSelectionModel] = useState<any>({});
    const [pageSize, setPageSize] = useState<number>(10);
    const [columns, setColumns] = useState<GridColDef[]>()
    const [serchCols, setSearchCols] = useState<string[]>([])
    const [tns, setTNS] = useState<tableSchemeAndData>();
    const [error, setError] = useState<boolean>(false);
    const [note, setNote] = useState<string>();
    const [open, setOpen] = useState(false);


    // Imperative subscription
    //const [scrollPosition, setScrollPosition] = useState<number>(0);


    // Restore state from `location.state` (if available)
    useEffect(() => {
        console.log("sqlTableMagic onload")
        const loaded = searchParams.get("loaded" + uniqueId)
        if (loaded == null) {
            setSearchParams((prev) => {
                prev.set("loaded" + uniqueId, Date.now().toString())
                // prev.set(__SCROLL_LEFT, debouncedSearchTerm.left.toString())
                return prev
            }, { replace: true })
        } else {
            const stateT = window.localStorage.getItem("sqlTableMagic" + uniqueId + loaded);
            if (stateT !== null) {
                const state = JSON.parse(stateT) as SqlMagicState | undefined;
                console.log("STATE", stateT, state)
                if (state && state) {
                    setPageSize(state.pageSize);
                    setPage(state.page);
                    setSortModel(state.sortModel);
                    setSelectionModel(state.selectionModel);
                    setSearch(state.search)
                    window.scrollTo(0, state.scrollPosition);
                }
            }
        }
    }, []);

    useEffect(() => {
        console.log("save effect", search)
        const thisPageState: SqlMagicState = {
            scrollPosition: window.scrollY,
            pageSize,
            page,
            sortModel,
            selectionModel,
            search,
        };
        const loaded = searchParams.get("loaded" + uniqueId)

        window.localStorage.setItem("sqlTableMagic" + uniqueId + loaded, JSON.stringify(thisPageState));

    }, [pageSize, page, sortModel, selectionModel, search]);



    const handleNavigateAway = (navigateToS: string) => {
        // alert("naviugatre")
        // const thisPageState: SqlMagicState = {
        //     scrollPosition: window.scrollY,
        //     pageSize,
        //     page,
        //     sortModel,
        //     selectionModel,
        //     search,
        // };
        // const loaded = searchParams.get("loaded"+uniqueId)
        // window.localStorage.setItem("sqlTableMagic" + uniqueId + loaded, JSON.stringify(thisPageState));

        navigateTo(navigateToS);
    };





    const handleOpen = (note: string) => {
        setNote(note)
        setOpen(true);
    }
    const handleClose = () => {
        setNote('')
        setOpen(false)
    };

    const ReloadTable = async () => {
        console.log("Reload Table")
        await Go(true)
    }
    const mounted = useRef(false);



    const onPaginationModelChange = (model: any) => {
        setPage(model.page);
        setPageSize(model.pageSize)
    }

    const paginationModel = { pageSize: pageSize, page: page } /// mui 7 pagination model


    const Go = async (partial = false) => {

        setError(false)
        if (partial === false) {
            setLoading(true)
        }

        if (mounted.current === false || partial === true) {
            mounted.current = true;

            try {
                const response = await doRequest<tableSchemeAndData>(url, requestType,)
                console.log("jobs", response)

                if (response === undefined) {
                    setError(true);
                    setLoading(false)
                } else {
                    if (response && response.schema[0] && response.schema[0].X && (response.schema[0].X === 'FAIL' || response.schema[0].X === "TIMEND")) {
                        if (response.schema[0].X === 'FAIL') {
                            logOut && logOut();
                            navigate && navigate('/login')
                        } else if (response.schema[0].X === 'TIMEND') {
                            setError(true);
                            setLoading(false)
                        }

                    } else {

                        if (response && response.schema && response.data) {
                            if (partial === false) {
                                const searchCols: string[] = [];


                                const burgers = response.schema.filter(d => d.T === 'BURGER')

                                console.log(burgers)
                                let xcolumns: GridColDef[] = [];
                                if (multi === true) {
                                    xcolumns.push({
                                        ...GRID_CHECKBOX_SELECTION_COL_DEF,

                                        flex: 1, minWidth: 100
                                    })
                                }
                                if (burgers.length > 0) {
                                    xcolumns.push({
                                        field: 'na', headerName: '', sortable: false, flex: 0.75, renderCell: (params: GridRenderCellParams) => {
                                            return <BurgerMenu actions={burgers} row={params.row} onClose={ReloadTable} />;
                                        },
                                    })
                                }

                                const added: string[] = [];
                                response.schema.filter(d => d.T !== 'BURGER' && d.T !== 'OPENROW').map((d, dI) => {
                                    let Field: string = d.F;
                                    let actualField: string = d.F;
                                    let flex: number = d.W;
                                    let minWidth = flex * 100;
                                    //     flex = 1;
                                    const currentMatches = added.filter(dd => dd === Field);

                                    added.push(Field)

                                    if (currentMatches.length > 0) {
                                        Field = Field + currentMatches.length;
                                    }

                                    // console.log("FUCK SAKE", flex, Field, currentMatches.length)
                                    switch (d.T) {
                                        case 'BUTTONFIELD':
                                            searchCols.push(d.F);
                                            //   console.log(d)
                                            xcolumns.push({
                                                field: Field, minWidth: minWidth, sortable: true, disableColumnMenu: true, headerName: d.FN, flex: flex, 
                                                
                                                valueGetter: (value, row) => {
//                                                    console.log(row[actualField])
                                                    return row[actualField];

                                                },
                                                renderCell: (params: GridRenderCellParams) => {


                                                    let Content = params.row[actualField];

                                                    let link = '';
                                                    if (d.LF === 'OPENJOB') {
                                                        link = `/jobs/${params.row["JobID"]}`
                                                    }
                                                    if (d.LF === 'OPENREPAIR') {
                                                        const jobID = (params.row['JobID'] ? params.row['JobID'] : 'no field');
                                                        link = `/jobs/${jobID}`
                                                    }
                                                    if (d.LF === 'OPENQUOTE') {
                                                        link = `/quote/${params.row["JobID"]}`
                                                    }
                                                    if (d.LF === 'OPENAPPLIANCE') {
                                                        link = `/assets/${params.row["ApplianceID"]}`
                                                    }
                                                    if (d.LF === 'OPENLOCATION') {
                                                        link = `/locations/${params.row["CompanyLocationID"]}`
                                                    }
                                                    if (Content === 0) {
                                                        return '';
                                                    } else {
                                                        return <Button onClick={(ev) => {
                                                            ev.preventDefault();
                                                            handleNavigateAway(link)
                                                        }} variant="contained">{Content}</Button>
                                                    }


                                                },
                                            })
                                            break;
                                        case 'BUTTON':

                                            //console.log(d)
                                            xcolumns.push({
                                                field: Field, minWidth: minWidth, sortable: false, type: 'number', disableColumnMenu: true, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams) => {
                                                    const Content = params.row[actualField];
                                                    const JobQuoteStatusID = params.row["JobQuoteStatusID"];
                                                    //    console.log("JobQuoteStatusID", JobQuoteStatusID)
                                                    if (d.LF === 'APPROVE') {


                                                        return <>{(JobQuoteStatusID === 1 || JobQuoteStatusID === -2) && <Approve object={params.row} jobId={Content} onClose={ReloadTable} />}</>
                                                    } else if (d.LF === 'DECLINE') {
                                                        return <>{(JobQuoteStatusID === 1 || JobQuoteStatusID === -2) && <Decline jobId={Content} onClose={ReloadTable} />}</>
                                                    } else {
                                                        return <></>
                                                    }
                                                },
                                            })
                                            break;
                                        case 'TEXT':
                                            searchCols.push(d.F);
                                            xcolumns.push({
                                                field: Field, minWidth: minWidth, disableColumnMenu: true, sortable: false, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams) => {

                                                    const Content = params.row[actualField];


                                                    if (Content == null) {
                                                        return '';
                                                    }

                                                    return `${Content}`;


                                                },
                                            })
                                            break;
                                        case 'TEXTSORT':
                                            searchCols.push(d.F);
                                            xcolumns.push({
                                                field: Field, minWidth: minWidth, disableColumnMenu: true, sortable: true, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams) => {

                                                    const Content = params.row[actualField];


                                                    if (Content == null) {
                                                        return '';
                                                    }

                                                    return `${Content}`;


                                                },
                                            })
                                            break;
                                        case 'TEXTFILTER':
                                            searchCols.push(d.F);
                                            xcolumns.push({
                                                field: Field, minWidth: minWidth, sortable: false, disableColumnMenu: false, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams) => {

                                                    const Content = params.row[actualField];


                                                    if (Content == null) {
                                                        return '';
                                                    }

                                                    return `${Content}`;


                                                },
                                            })
                                            break;
                                        case 'FILE':

                                            xcolumns.push({
                                                field: d.F, minWidth: minWidth, sortable: false, disableColumnMenu: true, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams) => {
                                                    const Content = params.row[actualField];
                                                    if (Content == null) {
                                                        return '';
                                                    }
                                                    return <Download variant="outlined" buttonText='' cacheId={Content} />
                                                },
                                            })
                                            break;
                                        case 'MEMO':
                                            searchCols.push(d.F);
                                            xcolumns.push({
                                                field: Field, minWidth: minWidth, disableColumnMenu: false, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams) => {
                                                    const Content = params.row[actualField];

                                                    if (Content == null) {
                                                        return '';
                                                    }

                                                    return `${Content}`;


                                                },
                                            })
                                            break;
                                        case 'DATE':

                                            xcolumns.push({
                                                field: Field, minWidth: minWidth, type: 'date', disableColumnMenu: true, headerName: d.FN, flex: flex, valueGetter: ((value: string) => {
                                                    if (value === null) {
                                                        return null
                                                    } else {

                                                        const d: Date = new Date(Date.parse(value));

                                                        return d;
                                                    }
                                                }), renderCell: (params: GridRenderCellParams) => {
                                                    const Content = params.row[actualField];
                                                    if (Content == null) {
                                                        return '';
                                                    }
                                                    const d: Date = new Date(Date.parse(Content.toString()));


                                                    let date, month, year;

                                                    date = d.getDate();
                                                    month = d.getMonth() + 1; // take care of the month's number here ⚠️
                                                    year = d.getFullYear();
                                                    return <>{`${date}/${month}/${year}`}</>
                                                },
                                            })
                                            break;
                                        case 'YESNO':

                                            return {
                                                field: Field, minWidth: minWidth, type: 'boolean', disableColumnMenu: true, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams) => {
                                                    const Content = params.row[actualField];
                                                    if (Content == null) {
                                                        return '';
                                                    }

                                                    if (Content) {
                                                        return <CheckIcon />
                                                    } else {
                                                        return <CloseIcon />
                                                    }

                                                },
                                            }
                                        case 'MONEY':


                                            xcolumns.push({
                                                field: Field, minWidth: minWidth, type: 'number', disableColumnMenu: true, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams) => {
                                                    const Content = params.row[actualField];
                                                    if (Content == null) {
                                                        return '';
                                                    }


                                                    return <Box sx={{ width: "100%", textAlign: "left" }}>{`£ ${Content.toFixed(2)}`}</Box>;
                                                },
                                            })
                                            break;
                                        case 'PHONE':

                                            xcolumns.push({
                                                field: Field, minWidth: minWidth, disableColumnMenu: true, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams) => {

                                                    const Content = params.row[actualField];

                                                    if (Content == null) {
                                                        return '';
                                                    }

                                                    // const valueFormatted = params.value;
                                                    return <Button variant='text' href={`tel:${Content}`}><PhoneIcon /></Button>;
                                                },
                                            })
                                            break;
                                        case 'EMAIL':


                                            xcolumns.push({
                                                field: Field, minWidth: minWidth, disableColumnMenu: true, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams) => {
                                                    const Content = params.row[actualField];
                                                    if (Content == null) {
                                                        return '';
                                                    }

                                                    // const valueFormatted = params.value;
                                                    return <Button variant='text' href={`mailto:${Content}&subject=Email`}><EmailIcon /></Button>;
                                                }
                                            })
                                            break;
                                        case 'NUM':

                                            xcolumns.push({
                                                field: Field, minWidth: minWidth, type: 'number', disableColumnMenu: true, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams) => {
                                                    const Content = params.row[actualField];

                                                    if (Content == null) {
                                                        return '';
                                                    }

                                                    const valueFormatted = Number(Content);
                                                    return `${valueFormatted}`;
                                                },
                                            })
                                            break;
                                        case 'OPENNOTE':


                                            xcolumns.push({
                                                field: Field, minWidth: minWidth, disableColumnMenu: true, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams) => {
                                                    const Content = params.row[actualField];
                                                    if (Content == null) {
                                                        return '';
                                                    }
                                                    //       console.log("c", Content, actualField)

                                                    return <>{Content}</>
                                                }
                                            })
                                            break;
                                        default:
                                            console.log("WTF", d)
                                            return {
                                                field: Field, minWidth: minWidth, disableColumnMenu: true, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams) => {
                                                    const Content = params.row[actualField];

                                                    return <>unknown {d.T} {Content}</>


                                                },
                                            }
                                    }
                                });

                                xcolumns = xcolumns.map(x => {
                                    x.headerAlign = "left";
                                    x.align = 'left';
                                    return x;
                                })
                                setSearchCols(searchCols);
                                setColumns(xcolumns);
                                setTNS(response);
                                setOutputPayload(response.data)
                                setLoading(false)
                            } else {
                                setOutputPayload(response.data)

                            }

                        }
                    }
                }

            } catch (e) {
                setError(true);
                setLoading(false)
            }
        }
    }


    useEffect(() => {
        console.log("triggered this", url, triggerUrl)
        if (triggerUrl === url) {
            console.log("triggered this WIN", url, triggerUrl)
            ReloadTable();
            trigger && trigger('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerUrl])


    useEffect(() => {

        Go();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        if (tns) {
            const original: any[] = [...tns.data]
            //  console.log("Search", search, serchCols)
            if (search && search.length > 0) {
                const newPayload = original.filter((value, index) => {
                    let retOk = false;
                    for (let index = 0; index < serchCols.length; index++) {
                        const col = serchCols[index];
                        const vvalue = value[col]
                        if (vvalue && vvalue !== null) {
                            const colPayload = vvalue.toString().toLowerCase();

                            if (colPayload.indexOf(search.toLowerCase()) >= 0) {
                                retOk = true;
                            }
                        }

                    }
                    if (retOk === true) {
                        return value;
                    } else {
                        return undefined
                    }
                })

                setOutputPayload(newPayload);
            } else {
                setOutputPayload(original);
            }



        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, tns])

    if (loading === true) {
        return <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'

        }}>

            <Box sx={{ margin: "auto", paddingTop: 20, paddingBottom: 20 }}>

                <img src="/Airevalley-Transparent-small-1.png" alt="Logo" />
                <br />
                <CircularProgress color='error' />

            </Box>

        </Box>
    }

    if (error === true) {
        return <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
        }}>
            <Box sx={{ margin: "auto", paddingTop: 20 }}>


                <Typography variant="h5">Error</Typography>
                <Typography>Something went wrong there.<br />
                    {url}</Typography>

            </Box>

        </Box>
    }


    //https://mui.com/x/api/data-grid/data-grid/
    const alt = tns && tns.schema.filter(d => d.T === 'BURGER' || d.T === 'ROWLINK')

    const handleEvent: GridEventListener<'rowClick'> = (
        params: GridRowParams,
        event: MuiEvent<React.MouseEvent<HTMLElement>>,

    ) => {

        const alt = tns && tns.schema.filter(d => d.T === 'OPENROW')[0]
        // if (apiRef.current && apiRef.current.getScrollPosition) {
        //     const pos = apiRef.current.getScrollPosition();
        //     console.log(pos)
        // }
        // getScroll();
        // console.log(params, event, details);
        if (event && event.target instanceof Element && event.target.nodeName === 'DIV') {
            console.log("handleEvent", event.target.nodeName)
            let url = '';
            if (alt) {
                const value = params.row[alt.F]
                switch (alt.LF) {
                    case 'OPENQUOTE':
                        url = `/quote/${value}`;
                        break;
                    case 'OPENJOB':
                        url = `/jobs/${value}`;
                        break;
                    case 'OPENAPPLIANCE':
                        url = `/assets/${value}`;
                        break;
                    case 'OPENLOCATION':
                        url = `/locations/${value}`;
                        break;
                }


                if (url !== '') {
                    handleNavigateAway(url)
                }



            } else {
                const openNote = tns && tns.schema.filter(d => d.T === 'OPENNOTE')[0]
                if (openNote) {
                    const value = params.row["Note"];
                    console.log(openNote, value)
                    handleOpen(value)
                }
            }
        }


    };
    //MuiDataGrid-row--borderBottom css-yrdy0g-MuiDataGrid-columnHeaderRow
    const props: any = {
        disableColumnSelector: true,
        disableSelectionOnClick: true,
        rows: outputPayload.filter(d => {
            //    console.log("searchm", search)

            if (!search && (query || query1)) {
                let actQuery = query;
                if (actQuery === undefined) actQuery = {}
                if (query1 !== undefined) actQuery = { ...actQuery, ...query1 }


                let ret = false;
                const keys = Object.keys(actQuery);
                //      console.log("filter", actQuery, keys, d, d.StatusType)

                if (keys.length > 0) {
                    for (let index = 0; index < keys.length; index++) {
                        // try {
                        const key = keys[index];
                        // const data = d[key];
                        // const queryValue = actQuery[key];
                        if (d[key] === actQuery[key]) {
                            ret = true
                        }
                        // } catch (s) {

                        // }
                    }

                    if (ret === true) {
                        return d;
                    } else {
                        return undefined
                    }
                } else {
                    return d;
                }
            } else {
                return d
            }
        }),
        sortModel: sortModel,
        onSortModelChange: (newSortModel: any) => setSortModel(newSortModel),
        columns: columns,
        disableColumnFilter: true,
        slots: {
            //  row: CustomGridRow,
            toolbar: CustomToolbar,
            noRowsOverlay: MissingRows,
            columnMenu: (props: any) => {
                const d = outputPayload.map(d => d[props.currentColumn.field]);
                let unique = [...new Set(d)];
                console.log(unique)

                let querysSet: string[] = [];
                let showReset = false;
                if (query1) {

                    querysSet = Object.getOwnPropertyNames(query1)
                    showReset = (querysSet.length > 0 ? true : false)
                }
                return <Box sx={{ width: '100%', maxWidth: 360, mb: 20, border: 1 }}>

                    <List component="nav" aria-label="secondary mailbox folder">
                        {showReset === true && <ListItemButton
                            onClick={(ev) => {
                                ev.preventDefault();
                                const searchD: any = {}

                                setQuery1(searchD)
                            }}>
                            <ListItemText primary="Reset" />
                        </ListItemButton>}



                        {unique.map(d => {
                            let selected = false;
                            if (query1 && query1[props.currentColumn.field] && query1[props.currentColumn.field] === d) {
                                selected = true
                            }
                            return <ListItemButton
                                selected={selected}
                                key={d}
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    const searchD: any = {}
                                    searchD[props.currentColumn.field] = d
                                    setQuery1(searchD)

                                }}>    <ListItemText primary={d} />
                            </ListItemButton>
                        }
                        )}
                    </List>
                </Box >
            },
        },

        slotProps: {
            row: { cmr: customMobileRow, },
            toolbar: { combinedSearch, search, setSearch, tblIndex },
            //  columnMenu: { combinedSearch, search, setSearch }
        },
        pageSizeOptions: [10, 25, 50, 100],
        paginationModel: paginationModel,
        onPaginationModelChange: onPaginationModelChange,
        getRowClassName: (params: any) => {
            if (params.row["Appliance_Background"]) {
                return params.row["Appliance_Background"]
            } else {
                return "";
            }

        },
        onRowClick: handleEvent,
        initialState: {
            sorting: {
                sortModel: [
                    { field: 'id', sort: 'desc' }
                ]
            },
        }

    }
    if (multi === true) {
        props.checkboxSelection = true
        props.onSelectionModelChange = (newSelectionModel: any) => {
            setSelectionModel(newSelectionModel);
        }
        props.selectionModel = selectionModel;
        props.componentsProps.toolbar.selectionModel = selectionModel;
        props.componentsProps.toolbar.doWithMulti = doWithMulti;
        if (tns) {
            props.componentsProps.toolbar.data = tns.data;
        }
    }
    console.log(url)



    return <Box>
        <SimpleDialog
            note={note}
            open={open}
            onClose={handleClose}
            onCancel={handleClose}
        />
        <ErrorBoundary>
            {title !== '' && <h2>{title}</h2>}

            {error === false ? <>
                {matches && customMobileRow ? <SqlTableMagicMobile
                    page={page + 1}
                    setPage={(p) => {
                        setPage(p - 1)
                    }}
                    perPage={pageSize}
                    setPerPage={(ps) => {

                    }}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel: any) => setSortModel(newSortModel)}
                    data={outputPayload.filter(d => {
                        //    console.log("searchm", search)

                        if (!search && (query || query1)) {
                            let actQuery = query;
                            if (actQuery === undefined) actQuery = {}
                            if (query1 !== undefined) actQuery = { ...actQuery, ...query1 }


                            let ret = false;
                            const keys = Object.keys(actQuery);
                            //      console.log("filter", actQuery, keys, d, d.StatusType)

                            if (keys.length > 0) {
                                for (let index = 0; index < keys.length; index++) {
                                    // try {
                                    const key = keys[index];
                                    // const data = d[key];
                                    // const queryValue = actQuery[key];
                                    if (d[key] === actQuery[key]) {
                                        ret = true
                                    }
                                    // } catch (s) {

                                    // }
                                }

                                if (ret === true) {
                                    return d;
                                } else {
                                    return undefined
                                }
                            } else {
                                return d;
                            }
                        } else {
                            return d
                        }
                    })} customMobileRow={customMobileRow} /> : <Box sx={{ height: height || '700px', width: "100%" }}>

                    {columns !== undefined && <DataGrid
                        apiRef={apiRef}

                        {...props}
                    />}
                </Box>}

            </> : <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center'
            }}>

                <Box sx={{ margin: "auto", paddingTop: 20 }}>
                    <img src="/Airevalley-Transparent-small-1.png" alt="Logo" />

                    <Typography variant="h5">Error</Typography>
                    <Typography>Something went wrong there.</Typography>

                </Box>

            </Box>}

            {tns && <>

                <DebugButton data={tns} />
                <DebugButton buttonText='SCHEMA' data={tns.schema} />
                <DebugButton buttonText='SCHEMA' data={alt} />
            </>}



        </ErrorBoundary>
    </Box >
}

export default SqlTableMagic;