import { Grid, TableHead } from "@mui/material";
import GreyBox, { alwaysFullSpacing } from "../../util/greyBox";
import { useEffect, useMemo, useRef, useState } from "react";
import doRequest from "../../util/axios";
import DebugButton from "../../util/debugButton";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Order, ScheduleHeader, getComparator, stableSort } from "./utils";
import Download from "../../util/download";


interface iState {
    order:string, orderBy:string,
    scrollPosition: number;
}



const SiteServiceSchedule = () => {
    const mounted = useRef(false)
    const [data, setData] = useState<any[]>([]);

    const [serviceId, setServiceId] = useState(0);
    const [certificateId, setCertificateId] = useState(0);
    const [name, setName] = useState("");

    const navigate = useNavigate();
    const [orderBy, setOrderBy] = useState<string>("Site");
    const [order, setOrder] = useState<Order>("asc");



    let [searchParams, setSearchParams] = useSearchParams();
    const uniqueId = "SiteServiceSchedulePg"
    useEffect(() => {

        const loaded = searchParams.get("loaded" + uniqueId)
        if (loaded == null) {
            setSearchParams((prev) => {

                prev.set("loaded" + uniqueId, Date.now().toString())
                // prev.set(__SCROLL_LEFT, debouncedSearchTerm.left.toString())
                return prev
            }, { replace: true })

        } else {
            const stateT = window.localStorage.getItem(uniqueId + loaded);
            if (stateT !== null) {
                const state = JSON.parse(stateT) as iState | undefined;
                console.log("STATE", stateT, state)
                if (state && state) {

                    window.scrollTo(0, state.scrollPosition);
                }
            }

        }
    }, []);


    useEffect(() => {
        const thisPageState: iState = {
            scrollPosition: window.scrollY,
            orderBy, order
        };
        const loaded = searchParams.get("loaded" + uniqueId)

        window.localStorage.setItem(uniqueId + loaded, JSON.stringify(thisPageState));

    }, [orderBy, order]);
 

    const beforeNavigate = () => {
        const thisPageState: iState = {
            scrollPosition: window.scrollY,
            orderBy, order
        };
        const loaded = searchParams.get("loaded" + uniqueId)
        window.localStorage.setItem(uniqueId + loaded, JSON.stringify(thisPageState));
    };








    const { id } = useParams();

    useEffect(() => {

        const Go = async () => {
            const response = await doRequest<any>(`reports/sReportServiceSchedule/${id}`, 'GET', {})
            console.log(response)
            setData(response);

            if (response.length >= 1) {
                setName(response[0].ApplianceSite)
                setServiceId(response[0].SiteServiceCacheID)
                setCertificateId(response[0].SiteGasCertID)
            }
        }
        if (mounted.current === false) {
            mounted.current = true;

            Go();
        }
    }, [id]);


    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: string,
    ) => {
        console.log(event)
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const visibleRows = useMemo(
        () =>
            stableSort(data, getComparator(order, orderBy)),
        [order, orderBy, data],
    );

    return <Grid container spacing={0}>
        <Grid item {...alwaysFullSpacing}>
            <GreyBox>
                <Grid container>
                    <Grid item md={8}>
                        <h2>Service Schedule {name}
                        </h2>
                    </Grid>
                    <Grid item md={4} sx={{
                        textAlign: 'right', mt: 2
                    }}>
                        {serviceId !== 0 && <Download className="" buttonText="Last Service Report" cacheId={serviceId} />}
                        {certificateId !== 0 && <Download className="" buttonText="Gas Certificate" cacheId={certificateId} />}
                    </Grid>
                    <Grid item md={12}>

                        <TableContainer sx={{ maxHeight: "70vh" }}>
                            <Table sx={{ minWidth: 500 }} aria-label="Site" stickyHeader>
                                <TableHead>

                                    <TableRow>
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="ApplianceAssetNumber" title="Asset Number" onRequestSort={handleRequestSort} />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="ApplianceType" title="Appliance" onRequestSort={handleRequestSort} align="center" />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="ApplianceManufacturer" title="Make" onRequestSort={handleRequestSort} align="center" />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="ApplianceModel" title="Model" onRequestSort={handleRequestSort} align="center" />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="ApplianceSerialNumber" title="Serial Number" onRequestSort={handleRequestSort} align="center" />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="ApplianceGER" title="Type" onRequestSort={handleRequestSort} align="center" />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="ApplianceChecklistConditionRating" title="Condition" onRequestSort={handleRequestSort} align="center" />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="AppliancePPM" title="Cost for Service" onRequestSort={handleRequestSort} align="center" />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="TwelveMonthRepairCost" title="12 Month Repair Cost" onRequestSort={handleRequestSort} align="center" />

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(visibleRows
                                    ).map((row) => {
                                        if (typeof row.Appliance_Background === "string") {
                                            return <TableRow key={row.name} className={row.Appliance_Background} onClick={(ev) => {
                                                ev.preventDefault();
                                                beforeNavigate();
                                                navigate(`/assets/${row.ApplianceID}`)
                                            }}>
                                                <TableCell component="th" style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }}>
                                                    {row.ApplianceAssetNumber}
                                                    <DebugButton data={data} />
                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center">
                                                    {row.ApplianceType}
                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center">
                                                    {row.ApplianceManufacturer}
                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center">
                                                    {row.ApplianceModel}
                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center">
                                                    {row.ApplianceSerialNumber}

                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center">
                                                    {row.ApplianceGER}

                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center">
                                                    {row.ApplianceChecklistConditionRating}

                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center">
                                                    {typeof row.AppliancePPM === 'number' && "£ " + row.AppliancePPM.toFixed(2)}
                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center">
                                                    {typeof row.TwelveMonthRepairCost === 'number' && "£ " + row.TwelveMonthRepairCost.toFixed(2)}

                                                </TableCell>
                                            </TableRow>
                                        } else {
                                            return <></>
                                        }
                                    })}

                                </TableBody>

                            </Table>
                        </TableContainer>
                    </Grid>

                </Grid>
            </GreyBox>
        </Grid>

    </Grid>
}


export default SiteServiceSchedule