import { Grid, IconButton, Tooltip, Typography, Box } from "@mui/material";

import { useMemo, } from "react";
import GreyBox, { alwaysFullSpacing, containerSpacing } from "../../util/greyBox";
import CloseIcon from '@mui/icons-material/Close';
import AutoCompleteSelect from "../../util/fields/autocomplete-select";
import { FieldArray } from "react-final-form-arrays";
import TextInputField from "../../util/fields/text-field";
import { useForm, useFormState } from "react-final-form";

interface iProps {
    locations: any[];
    assets: any[];
    error?: string
    setError: (error: string) => void;
}
const NewJobForm = ({ locations, assets, error, setError }: iProps) => {


    const onSiteXS = {
        color: "white",
        textAlign: "center",
        backgroundColor: "red",
        paddingTop: 3,
        paddingBottom: 3,
        marginLeft: 1,
        marginRight: 1,
        fontWeight: 800,
    }
    const headSX = {

        paddingLeft: 1,
        paddingBottom: 1
    }
    const { values } = useFormState();
    const { change } = useForm();
    const useableAssets = useMemo(() => {
        return assets.filter((asset: any) => {
            if (values.location && values.location !== null) {
                if (asset.CompanyLocationID == values.location.id) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        })
    }, [values.location]);

    const setLocation = (value: any) => {
        console.log("value", value)
        if (values.location && values.assets.length > 0) {

            const res = window.confirm("You have already set a location, this will remove all added appliances.");
            if (res) {
                change("asset", undefined)
                change('assets', []);
                return true;
            } else {
                return false;
            }
        } else {
            return true
        }

    }

    return <Grid item  {...alwaysFullSpacing} >


        <Typography sx={headSX} variant="h3">Create New Job</Typography>



        <Grid item container  {...alwaysFullSpacing} spacing={0}>
            <Grid item  {...containerSpacing}>
                <GreyBox>

                    <Typography sx={headSX} variant="h4">Site Details</Typography>

                    <AutoCompleteSelect label="Location" name="location" newObject={{ id: "new", CompanyLocation1Address: "New Location" }} onChange={setLocation} options={locations} required={true} getOptionKey={(value: any) => {
                        console.log("key", value)
                        if (value && value !== null && value.id) {
                            return value.id
                        } else {
                            return null;
                        }

                    }} getOptionLabel={(value: any) => {
                        console.log(value)
                        if (value && value !== null) {
                            return value.CompanyLocation1Address
                        } else {
                            return ''
                        }
                    }} />

                    {values.location && values.location.id === "new" && <div>
                        <TextInputField name={`location.LocationBuilding`} label="Building" required={true} />
                        <TextInputField name={`location.LocationAddress`} label="Address" required={true} />

                    </div>}
                </GreyBox>

            </Grid>

            <Grid item  {...containerSpacing}>
                <GreyBox>

                    <Typography sx={headSX} variant="h4">Appliances</Typography>
                    <FieldArray name="assets">
                        {({ fields }: any) => {
                            const addToAssets = (value: any) => {
                                console.log("value", value)
                                if (value.id && fields.value.indexOf((d: any) => d.id === value.id) === -1) {
                                    setError("")
                                    if (value.id === "new") {
                                        value.id = Date.now();
                                        value.isNew = true;
                                    }
                                    fields.push(value)
                                    return true;
                                } else {
                                    alert("Sorry can not add the same appliance twice")
                                    return false;
                                }

                            }


                            return <>
                                <AutoCompleteSelect disabled={values.location ? false : true} newObject={{ id: "new", ApplianceType: "New Appliance" }} label="Add Asset" name="asset" options={useableAssets} onChange={addToAssets} getOptionKey={(value: any) => value.id} getOptionLabel={(value: any) => {
                                    if (value && value !== null) {
                                        return `${value.ApplianceType} #${value.ApplianceSerialNumber}`
                                    } else {
                                        return ''
                                    }
                                }} />
                                {!values.location && !(values.assets.length > 0) && <Typography color={"error"} sx={{ pl: 2 }}>{error}</Typography>}

                                {fields.map((name: string, index: number) => (
                                    <Box key={name} >

                                        <Typography sx={headSX} variant="h4">{fields.value[index] && fields.value[index].ApplianceType} #{fields.value[index] && fields.value[index].ApplianceSerialNumber}


                                            <IconButton sx={{ right: 10, position: "relative" }} onClick={() => {
                                                fields.remove(index)
                                            }}>
                                                <Tooltip title="Remove Appliance">
                                                    <CloseIcon color="error" />
                                                </Tooltip>
                                            </IconButton>
                                        </Typography>
                                        {fields.value[index] && fields.value[index].isNew === true && <>
                                            <TextInputField name={`assets[${index}].ApplianceManufacturer`} label="Manufacturer" required={true} />
                                            <TextInputField name={`assets[${index}].ApplianceModel`} label="Model" required={true} />
                                            <TextInputField name={`assets[${index}].ApplianceSerialNumber`} label="Serial Number" required={true} />
                                            <TextInputField name={`assets[${index}].ApplianceAssetNumber`} label="Asset Number" required={true} />
                                        </>}
                                        <TextInputField name={`assets[${index}].details`} label="Details of problem" required={true} />

                                    </Box>
                                ))}
                            </>
                        }}
                    </FieldArray>


                </GreyBox>

            </Grid>
            <Grid item  {...containerSpacing}>
                <GreyBox>
                    <Typography sx={headSX} variant="h4">Job Details</Typography>
                    <TextInputField name="callRef" label="Call Ref #" required={true} />
                </GreyBox>


            </Grid>


        </Grid>
    </Grid>
}


export default NewJobForm