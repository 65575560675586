import {  Grid } from "@mui/material";
import GreyBox from "../../util/greyBox";
import SqlTableMagic from "../../util/sqlTableMagic";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
interface iState {

    scrollPosition: number;
}


const Home = () => {
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [loadedB, setLoadedB] = useState(false);
    const [loadedC, setLoadedC] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    const uniqueId = "HomePg"
    useEffect(() => {



        const loaded = searchParams.get("loaded" + uniqueId)
        if (loaded == null) {
            setSearchParams((prev) => {

                prev.set("loaded" + uniqueId, Date.now().toString())
                // prev.set(__SCROLL_LEFT, debouncedSearchTerm.left.toString())
                return prev
            }, { replace: true })
            setLoaded(true)
            setTimeout(()=>{
                setLoadedB(true)
                setTimeout(()=>{
                    setLoadedC(true)
                }, 1000);
            }, 1000)
        } else {
            const stateT = window.localStorage.getItem(uniqueId + loaded);
            if (stateT !== null) {
                const state = JSON.parse(stateT) as iState | undefined;
                console.log("STATE", stateT, state)
                if (state && state) {

                    window.scrollTo(0, state.scrollPosition);
                }
            }
            setLoaded(true)
            setTimeout(()=>{
                setLoadedB(true)
                setTimeout(()=>{
                    setLoadedC(true)
                }, 1000);
            }, 1000)
        }
    }, []);


    const navigateTo = (navigateTo: string) => {
        const thisPageState: iState = {
            scrollPosition: window.scrollY,

        };
        const loaded = searchParams.get("loaded" + uniqueId)
        window.localStorage.setItem(uniqueId + loaded, JSON.stringify(thisPageState));
        navigate(navigateTo, {});
    };



    return <Grid container spacing={2}>
        {loaded && <>
            <Grid item xs={12} md={12}>
                <GreyBox>
                    <SqlTableMagic navigateTo={navigateTo} title="Live Quotes" url="quotes/Home" requestType='GET' tblIndex={0} uniqueId="homeLiveQuotes"/>
                </GreyBox>
            </Grid>
            {loadedB && <Grid item xs={12} md={12}>
                <GreyBox>
                    <SqlTableMagic navigateTo={navigateTo} title="Live Jobs" url="jobs/sHomeLiveJob" requestType='GET' tblIndex={1} uniqueId="homeLiveJobs" />
                </GreyBox>
            </Grid>}
            {loadedC && <Grid item xs={12} md={12}>
                <GreyBox>
                    <SqlTableMagic navigateTo={navigateTo} title="Complete Jobs" url="jobs/sHomeCompleteJob" requestType='GET' tblIndex={2} uniqueId="homeCompleteJobs"/>
                </GreyBox>
            </Grid>}
        </>}
        

    </Grid>
}


export default Home