import { Button, CircularProgress, Grid, TableHead } from "@mui/material";
import GreyBox, { alwaysFullSpacing } from "../../util/greyBox";

import { useEffect, useMemo, useRef, useState } from "react";
import doRequest from "../../util/axios";
import DebugButton from "../../util/debugButton";
import dayjs, { Dayjs } from 'dayjs';
import { SxProps } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/en-gb';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Order, ScheduleHeader, getComparator, stableSort } from "./utils";
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { UseDateFieldProps } from '@mui/x-date-pickers/DateField';
import {
    BaseSingleInputFieldProps,
    DateValidationError,
    FieldSection,
} from '@mui/x-date-pickers/models';
import { useSearchParams } from "react-router-dom";

interface ButtonFieldProps
    extends UseDateFieldProps<Dayjs>,
    BaseSingleInputFieldProps<
        Dayjs | null,
        Dayjs,
        FieldSection,
        DateValidationError
    > {
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

function ButtonField(props: ButtonFieldProps) {
    const {
        setOpen,
        label,
        id,
        disabled,
        InputProps: { ref } = {},
        inputProps: { 'aria-label': ariaLabel } = {},
    } = props;

    return (
        <Button
            variant="outlined"
            id={id}
            disabled={disabled}
            ref={ref}
            aria-label={ariaLabel}
            onClick={() => setOpen?.((prev) => !prev)}
        >
            {label ? `${label}` : 'Pick a date'}
        </Button>
    );
}

function ButtonDatePicker(
    props: Omit<DatePickerProps<Dayjs>, 'open' | 'onOpen' | 'onClose'>,
) {
    const [open, setOpen] = useState(false);

    return (
        <DatePicker
            slots={{ field: ButtonField, ...props.slots }}
            slotProps={{ field: { setOpen } as any }}
            {...props}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
        />
    );
}


const headXS: SxProps = {
    fontWeight: "bold", verticalAlign: "bottom"
}



interface iState {
    order:string, orderBy:string,
    scrollPosition: number;
    start:Dayjs | null;
    end:Dayjs | null;
}



const Spend = () => {
    const mounted = useRef(false)
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(true)
    const [start, setStart] = useState<Dayjs | null>(dayjs().add(-1, 'years'));
    const [end, setEnd] = useState<Dayjs | null>(dayjs());
 
   // const { CompanyHideGasCertificateYN, CompanyHideGasServiceYN, CompanyHideRefrigerationServiceYN, CompanyHideElectricalServiceYN, CompanyHideCoffeeYN } = useAuth();


   const [orderBy, setOrderBy] = useState<string>("Site");
   const [order, setOrder] = useState<Order>("asc");



   let [searchParams, setSearchParams] = useSearchParams();
   const uniqueId = "SiteServiceSchedulePg"
   useEffect(() => {

       const loaded = searchParams.get("loaded" + uniqueId)
       if (loaded == null) {
           setSearchParams((prev) => {

               prev.set("loaded" + uniqueId, Date.now().toString())
               // prev.set(__SCROLL_LEFT, debouncedSearchTerm.left.toString())
               return prev
           }, { replace: true })

       } else {
           const stateT = window.localStorage.getItem(uniqueId + loaded);
           if (stateT !== null) {
               const state = JSON.parse(stateT) as iState | undefined;
               console.log("STATE", stateT, state)
               if (state && state) {

                   window.scrollTo(0, state.scrollPosition);
               }
           }

       }
   }, []);


   useEffect(() => {
       const thisPageState: iState = {
           scrollPosition: window.scrollY,
           orderBy, order, start, end,
       };
       const loaded = searchParams.get("loaded" + uniqueId)

       window.localStorage.setItem(uniqueId + loaded, JSON.stringify(thisPageState));

   }, [orderBy, order, start, end]);


   const beforeNavigate = () => {
       const thisPageState: iState = {
           scrollPosition: window.scrollY,
           orderBy, order, start, end,
       };
       const loaded = searchParams.get("loaded" + uniqueId)
       window.localStorage.setItem(uniqueId + loaded, JSON.stringify(thisPageState));
   };







    const Go = async () => {
        setLoading(true)
        const response = await doRequest<any>(`reports/sReportSpends/?start=${start}&end=${end}`, 'GET', {})
        console.log(response)
        setData(response);
        setLoading(false)
    }
    useEffect(() => {


        if (mounted.current === false) {
            mounted.current = true;

            Go();
        }
    }, []);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: string,
    ) => {
        console.log(event)
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const visibleRows = useMemo(
        () =>
            stableSort(data, getComparator(order, orderBy)),
        [order, orderBy, data],
    );

    return <Grid container spacing={0}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-gb"}>
            <Grid item {...alwaysFullSpacing}>
                <GreyBox>
                    <Grid container>
                        <Grid item md={12}>
                            <h2>Spend</h2>
                            <Box sx={{ textAlign: 'center', pb: 4 }}>

                                <ButtonDatePicker
                                    label={start == null ? "Start" : "Start "+ start.format('DD/MM/YYYY')}
                                    value={start}
                                    onChange={(newValue) => setStart(newValue)}
                                />

                                <ButtonDatePicker
                                    label={end == null ? "End" : "End " + end.format('DD/MM/YYYY')}
                                    value={end}
                                    onChange={(newValue) => setEnd(newValue)}
                                />
                             

                                <Button onClick={(ev) => {
                                    ev.preventDefault();
                                    Go()
                                }}>Update Results</Button>
                            </Box>

                            {loading === true && <CircularProgress />}
                            <TableContainer sx={{ maxHeight: "70vh" }}>
                                {data.length > 0 && <Table aria-label="Spending" stickyHeader>
                                    <TableHead>


                                        <TableRow>
                                            <ScheduleHeader order={order} orderBy={orderBy} keyRef="Site" title="Site" onRequestSort={handleRequestSort} />

                                            <ScheduleHeader order={order} orderBy={orderBy} keyRef="Appliances" title="Appliances" onRequestSort={handleRequestSort} align="center" />

                                            <ScheduleHeader order={order} orderBy={orderBy} keyRef="RepairSpend" title="Repair Spend" onRequestSort={handleRequestSort} align="center" />
                                            <ScheduleHeader order={order} orderBy={orderBy} keyRef="ServicingSpend" title="Servicing Spend" onRequestSort={handleRequestSort} align="center"/>


                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{ overflowX: 'scrol', height: 500 }}>
                                        {visibleRows.map((row) => {

                                            return <TableRow key={row.name} onClick={(ev) => {
                                                ev.preventDefault();
                                                beforeNavigate()
                                                window.open(`/spend/${row.id}?start=${start}&end=${end}`);
                                            }}>
                                                <TableCell component="th" scope="row" style={{  borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }}>
                                                    {row.Site}
                                                    <DebugButton data={data} />
                                                </TableCell>
                                                <TableCell style={{ borderLeft: "1px solid rgba(255, 255, 255, 20)" , borderBottom: "1px solid rgba(255, 255, 255, 20)"  }} align="center">
                                                    {row.Appliances}
                                                </TableCell>
                                                <TableCell style={{  borderLeft: "1px solid rgba(255, 255, 255, 20)" , borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">
                                                    {typeof row.RepairSpend === 'number' && row.RepairSpend !== null && <>£   {row.RepairSpend.toFixed(2)}</>}
                                                </TableCell>

                                                <TableCell style={{ borderLeft: "1px solid rgba(255, 255, 255, 20)" , borderBottom: "1px solid rgba(255, 255, 255, 20)"  }} align="center">
                                                    {typeof row.ServicingSpend === 'number' && row.ServicingSpend !== null && <>£   {row.ServicingSpend.toFixed(2)}</>}
                                                </TableCell>
                                            </TableRow>
                                        })}




                                        <TableRow>
                                            <TableCell component="th" scope="row" style={{ borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)"  }} sx={headXS}>
                                                Total:
                                            </TableCell>
                                            <TableCell style={{  borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)"   }} align="center">

                                            </TableCell>
                                            <TableCell style={{  borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)"   }} sx={headXS} align="center">
                                                £ {data.reduce((accumulator, currentValue) => {
                                                    return accumulator + currentValue.RepairSpend
                                                }, 0).toFixed(2)}
                                            </TableCell>
                                            <TableCell style={{ borderLeft: "1px solid rgba(255, 255, 255, 20)" , borderBottom: "1px solid rgba(255, 255, 255, 20)" }} sx={headXS} align="center">
                                                £ {data.reduce((accumulator, currentValue) => {
                                                    return accumulator + currentValue.ServicingSpend
                                                }, 0).toFixed(2)}
                                            </TableCell>



                                        </TableRow>
                                    </TableBody>


                                </Table>}
                            </TableContainer>
                        </Grid>

                    </Grid>
                </GreyBox>
            </Grid>
        </LocalizationProvider>
    </Grid>
}


export default Spend