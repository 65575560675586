import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

export interface iSimpleDialogProps {
    open: boolean;
    note: string | undefined;
    onCancel: () => void;
    onClose: (value: string) => void;
}

const SimpleDialog = (props: iSimpleDialogProps) => {
    const { onClose, onCancel, open, note } = props;

    const handleClose = () => {

        onClose("text");

    };

  


    return (
        <Dialog onClose={handleClose} fullWidth open={open}>
            <DialogTitle> Note Details</DialogTitle>
            <DialogContent>
                {note && note !== '' && note.split("\n").map(function (item, idx) {
                    return (
                        <span key={idx}>
                            {item}
                            <br />
                        </span>
                    )
                })}



            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Close</Button>

            </DialogActions>

        </Dialog>
    );
}


export default SimpleDialog;