import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, TextField } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import doRequest from "./axios";
import { useNotify } from "./notify";
import { useTableContext } from "./table-provider";
import { buttonSX } from "./buttons";

interface iAddNote {
    jobId: any;
    object: any;
    className?: string;
    onClose: () => void;
    variant?: 'outlined' | 'text';
}

export interface iSimpleDialogProps {
    open: boolean;
    onCancel: () => void;
    onClose: (value: string, cc: boolean) => void;
}

const SimpleDialog = (props: iSimpleDialogProps) => {
    const { onClose, onCancel, open } = props;
   
    const [text, setText] = useState('');
    const [feedback, setFeedback] = useState('');
    const [checked, setChecked] = useState(false);
    const handleChangeCC = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const handleClose = () => {
        if (text !== '') {
            onClose(text, checked);
            setText("");
            setChecked(false);
        } else {
            setFeedback("Please set a Note")
        }

    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };


    return (
        <Dialog onClose={onCancel} fullWidth open={open}>
            <DialogTitle>Add note for account manager</DialogTitle>
            <DialogContent>

                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    value={text}
                    onChange={handleChange}
                    label="Note"
                    type="text"
                    multiline={true}
                    fullWidth
                    variant="standard"
                />

                {feedback !== '' && <>{feedback}</>}
                <FormGroup>
                    <FormControlLabel control={<Checkbox
                        checked={checked}
                        
                        onChange={handleChangeCC}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />} label="Email me a copy" />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Cancel</Button>
                <Button onClick={handleClose}>Send Note</Button>
            </DialogActions>

        </Dialog>
    );
}



const AddNote = ({ className = '', object, jobId, onClose, variant = 'outlined' }: iAddNote) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const location = useLocation();
    const { displayNote } = useNotify();
    const { trigger } = useTableContext()
    const handleClose = () => {
        //  onClose && onClose()
        setOpen(false)
    };
  
    const approveThis = async (text: string, cc: boolean) => {
        setLoading(true)
        try {
            handleClose();
            const response = await doRequest<any>(`note`, 'POST', { jobId, text: text, cc })
            console.log("static", response, location.pathname)
            if (response.X === 'OK') {
                console.log("static", response)
                displayNote && displayNote('You have created a note', 'success')

                
               // if (location.pathname.includes('/jobs')) {
                trigger &&  setTimeout(()=>trigger('jobs/sJobNotes/'+jobId), 1000)
             
                onClose()   
                setLoading(false)
            } else {
                displayNote && displayNote('Not valid', 'error')
                setLoading(false)
            }
        } catch {
            displayNote && displayNote('An Error occurred', 'error')
            setLoading(false)
        }
    }
    // const isArray = Array.isArray(jobId)
    const text = 'Add note for account manager'
    return <div>
        <SimpleDialog
            open={open}
            onClose={approveThis}
            onCancel={handleClose}
        />
        <Button sx={buttonSX} className={className} variant={variant} disabled={loading || object.JobQuoteStatusID === -2} onClick={async () => {
            handleOpen();
        }}>{loading ? "Loading" : text}</Button>


    </div>
}



export default AddNote;